import { LoginType } from './enums';
import { request } from '@/utils/request';

/**
 * 获取app版本号
 */
export const getAppVersion = async () => {
    return { version: '3.0.0.' };
    // return request.post<{ version: string }>('/Basic/getLatestVersion');
};

/**
 * 获取sessionTicket
 */
export const getSessionTicket = () => {
    return request.post<{ session_ticket: string }>('/student/Basic/createSessionTicket');
};

/**
 * 获取用户登录状态
 */
export const getUserLoginStatus = () => {
    return request.post<{ login_status: 1 | 0 }>('/student/User/getUserLoginStatus');
};

/**
 * 获取验证码
 */
export const getVerifyCode = (params: { phone: string; countryCode: string }) => {
    return request.post('/student-app/common/sms/sendSmsCode', {
        ...params,
        scene: 'login'
    });
};

/**
 * 手机号登录
 */
export const loginByPhone = (params: USER_API.LoginByPhoneReq) => {
    return request.post<USER_API.LoginResponse>('/student-app/student/auth/login', {
        grant: {
            type: LoginType.PHONE,
            phone: params
        }
    });
};

/**
 * 退出登录
 */
export const logout = () => {
    return request.post('/student-app/student/auth/logout');
};

/**
 * 查询是否是客服身份
 */
export const isCustomerService = () => {
    return request.post<1 | 0>('/student-app/user/info/isCustomer');
};

/**
 * 获取im账号签名 1: 客服身份
 */
export const getImSign = (type = 1) => {
    return request.post<{ sign: string; identifier: string }>('/student-app/im/user/sign', {
        type
    });
};

/**
 * 获取用户角色枚举
 */ /student-app/;
export const getUserRoleEnum = () => {
    return request.post<{ [key: number]: string }>('/student-app/im/user/role');
};

/**
 * 根据手机号获取用户ID
 */ /student-app/;
export const getUserIdByPhone = (params: { phone: string }) => {
    return request.post<string[]>('/student-app/im/user/search', params);
};

/**
 * 获取用户信息
 */ /student-app/;
export function getInfo() {
    return request.post<USER_API.UserInfo>('/student-app/customer/system/getKfInfo');
}

/**
 * 更新用户信息
 */ /student-app/;
export function updateUserInfo(data: USER_API.UserInfo) {
    return request.post('/student-app/customer/system/updateKfInfo', data);
}

/**
 * 获取国家地区码
 */
export function getCountryCodeList(key?: string) {
    return request.post<USER_API.ICountryCodeResponse>('/student-app/common/info/countryCodeList', {
        key
    });
}
