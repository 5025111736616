const components = {
    取消: '取消',
    完成: '完成',
    已选中: '已选中',
    人: '人',
    保存: '保存',
    请输入userID: '请输入userID',
    请输入groupID: '请输入groupID',
    确定: '确定',
    全选: '全选',
    已在群聊中: '已在群聊中',
    查看更多: '查看更多',
    '图片 url 不存在': '图片 url 不存在',
    已取消: '已取消',
    获取权限失败: '获取权限失败',
    已保存至相册: '已保存至相册',
    图片下载失败: '图片下载失败',
    暂不支持下载此类型图片: '暂不支持下载此类型图片'
};

export default components;
