import { message } from 'ant-design-vue';
import type { IConversationModel } from '@tencentcloud/chat-uikit-engine';
import { TUIConversationService } from '@tencentcloud/chat-uikit-engine';

enum ConversationActionEnum {
    Delete = 'delete',
    Top = 'top',
    Interrupt = 'interrupt'
}

export const actionMenuItems: {
    title: (conversation: IConversationModel) => string;
    value: ConversationActionEnum;
}[] = [
    {
        title: () => '删除会话',
        value: ConversationActionEnum.Delete
    },
    {
        title: (conversation: IConversationModel) =>
            conversation.isPinned ? '取消置顶会话' : '置顶会话',
        value: ConversationActionEnum.Top
    },
    {
        title: (conversation: IConversationModel) =>
            conversation.isMuted ? '取消消息免打扰' : '消息免打扰',
        value: ConversationActionEnum.Interrupt
    }
];

export function handleMenuItemAction(
    action: ConversationActionEnum,
    conversation: IConversationModel
) {
    switch (action) {
        case ConversationActionEnum.Delete:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            TUIConversationService.deleteConversation({
                conversationIDList: [conversation.conversationID],
                clearHistoryMessage: false
            });
            break;

        case ConversationActionEnum.Top:
            conversation.pinConversation();
            break;
        case ConversationActionEnum.Interrupt:
            conversation.muteConversation();
            break;
        default:
            message.error('不支持的操作');
    }
}
