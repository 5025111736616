const TUISearch = {
    搜索: '搜索',
    全部: '全部',
    联系人: '联系人',
    群聊: '群聊',
    聊天记录: '聊天记录',
    文本: '文本',
    文件: '文件',
    '图片/视频': '图片/视频',
    其他: '其他',
    选择时间: '选择时间',
    今天: '今天',
    近三天: '近三天',
    近七天: '近七天',
    查看更多: '查看更多',
    条相关: '条相关',
    条与: '条与',
    相关的: '相关的',
    进入聊天: '进入聊天',
    定位到聊天位置: '定位到聊天位置',
    搜索会话内容: '搜索会话内容',
    查看更多历史记录: '查看更多历史记录',
    暂无搜索结果: '暂无搜索结果',
    结果: '结果',
    '[文本]': '[文本]',
    '[图片]': '[图片]',
    '[语音]': '[语音]',
    '[视频]': '[视频]',
    '[文件]': '[文件]',
    '[自定义消息]': '[自定义消息]',
    '[系统消息]': '[系统消息]',
    '[合并消息]': '[合并消息]',
    '[位置消息]': '[位置消息]',
    '消息云端搜索失败：': '消息云端搜索失败：',
    '查找联系人失败：': '查找联系人失败：',
    '查找群聊失败：': '查找群聊失败：',
    进入会话失败: '进入会话失败'
};

export default TUISearch;
