import session from './session';
import customerManger from './customer-manager';
import account from './account';
import sessionRecord from './session-record';

// import externaLink from './externa-link';

export default [
    ...session,
    ...customerManger,
    ...account,
    ...sessionRecord
    // ...externaLink
];
