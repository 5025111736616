import { getGroupUserInfo } from '@/api/session';

class IndexDB {
    private db: IDBDatabase;
    constructor() {
        const IndexDbFactory =
            window.indexedDB ||
            window.webikitIndexedDB ||
            window.mozIndexedDB ||
            window.msIndexedDB;

        const request = IndexDbFactory.open('GROUP_PROFILE');

        request.onsuccess = () => {
            this.db = request.result;
        };

        request.onupgradeneeded = event => {
            this.db = event.target.result;

            if (!this.db.objectStoreNames.contains('profileStore')) {
                const objectStore = this.db.createObjectStore('profileStore', {
                    keyPath: 'groupId'
                });
                // unique name可能会重复
                objectStore.createIndex('nickName', 'nickName', { unique: false });
                objectStore.createIndex('headImg', 'headImg', { unique: false });
            }
        };
    }

    async getGroupUserInfo(groupId: string): Promise<{ nickName: string; headImg: string }> {
        return new Promise(resolve => {
            const transaction = this.db.transaction(['profileStore']);
            const objectStore = transaction.objectStore('profileStore');
            // 用户读取数据，参数是主键
            const dbRequest = objectStore.get(groupId);

            dbRequest.onsuccess = async () => {
                if (dbRequest.result) {
                    resolve(dbRequest.result);
                } else {
                    const data = await getGroupUserInfo({ groupId });

                    this.db
                        .transaction?.(['profileStore'], 'readwrite')
                        .objectStore('profileStore')
                        .add({ groupId, nickName: data.nickName, headImg: data.headImg });
                    resolve({
                        nickName: data.nickName,
                        headImg: data.headImg
                    });
                }
            };

            dbRequest.onerror = async () => {
                const data = await getGroupUserInfo({ groupId });
                resolve({
                    nickName: data.nickName,
                    headImg: data.headImg
                });
            };
        });
    }
}

export default new IndexDB();
