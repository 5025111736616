export enum ResultEnum {
    /** 成功 */
    SUCCESS = 1,
    /** 失败 */
    ERROR = 0,
    /** 未登录 */
    NOT_LOGIN = 1001,
    /**
     * 在其他设备登录
     */
    LOGIN_MULTI = 1010
}
