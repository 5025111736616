import type { RouteRecordRaw } from 'vue-router';

const moduleName = 'customerManager';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/customer-manager',
        name: moduleName,
        component: () => import('@/views/customer-manager/index.vue'),
        meta: {
            title: '客户管理',
            icon: 'zd-kehuguanli'
        }
    }
];

export default routes;
