<script setup lang="ts">
import { CopyOutlined } from '@ant-design/icons-vue';
import { useClipboard } from '@vueuse/core';
import { message } from 'ant-design-vue';
import { filterMoney } from '@/utils/goods';

const props = defineProps<{
    goodsId: string;
    goodsPrice: string;
    goodsTitle: string;
    goodsCover: string;
}>();

// const goodsUrl = computed(() => {
//     const baseUrl = import.meta.env.VITE_STUDENT_BASE_URL;
//     return `${baseUrl}/pages/mall/good-detail?goods_type=1&goods_no=${props.goodsId}&item_id=&from=recommend`;
// });
const loading = ref(true);

const { copy } = useClipboard();
const handleCopy = () => {
    copy(props.goodsId);
    message.success('复制成功');
};

// watch(visible, async () => {
//     if (visible.value) {
//         await nextTick();
//         iframeRef.value.onload = () => {
//             loading.value = false;
//         };
//     } else {
//         loading.value = true;
//     }
// });
</script>

<template>
    <div class="w-84 h-90px py-3 flex-y-center gap-4 cursor-pointer">
        <img
            class="w-70px h-70px flex flex-shrink-0"
            :src="props.goodsCover"
            alt=""
        />
        <div class="flex-col">
            <div class="line-clamp-2">
                {{ props.goodsTitle }}
            </div>
            <div class="text-gray text-sm mt-2">
                商品ID：{{ props.goodsId }}
                <CopyOutlined
                    class="cursor-pointer color-primary"
                    @click.stop="handleCopy"
                />
            </div>
            <div class="color-#FF004D mt-2">
                <span class="text-xs">¥</span>
                <span class="text-xl">{{ filterMoney(props.goodsPrice) }}</span>
            </div>
        </div>
    </div>
    <!-- 
    <a-modal
        v-model:open="visible"
        :width="375"
        class="h-700px p0"
        destroy-on-close
        :body-style="{ padding: '0!important' }"
        :footer="false"
        :close-on-click-modal="false"
    >
        <a-spin :spinning="loading">
            <iframe
                ref="iframeRef"
                :src="goodsUrl"
                width="375"
                height="700"
                class="border-0 ml--6 mt--5"
            ></iframe>
        </a-spin>
    </a-modal> -->
</template>
