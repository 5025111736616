<script lang="ts" setup>
import type { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { TUIChatEngine, TUIStore } from '@tencentcloud/chat-uikit-engine';
import TUICustomerServicePlugin from '@tencentcloud/tui-customer-service-plugin/index.vue';
import {
    isCustomerServicePluginMessage,
    isMessageInvisible
} from '@tencentcloud/tui-customer-service-plugin';

// import messageBubble from '../../components/TUIChat/message-list/message-elements/message-bubble.vue';
import MessageCallGroup from './message-call/message-call-group.vue';
import MessageCallC2C from './message-call/message-call-c2c.vue';

interface IProps {
    message: IMessageModel;
    blinkMessageIDList?: string[];
}

const props = withDefaults(defineProps<IProps>(), {
    message: () => ({} as IMessageModel),
    blinkMessageIDList: () => [] as string[]
});

const emits = defineEmits(['resendMessage', 'handleToggleMessageItem', 'handleH5LongPress']);
const messageModel = computed(() => TUIStore.getMessageModel(props.message?.ID));
const messageSignalingInfo = computed(() => messageModel?.value?.getSignalingInfo());
const messageCustomContent = computed(() => messageModel?.value?.getMessageContent());
// 需要展示ui的判断逻辑
const showStyle = computed(() => {
    if (messageSignalingInfo?.value?.businessID === 1) {
        if (messageModel.value?.conversationType === TUIChatEngine.TYPES.CONV_C2C) {
            return 'bubble';
        }
        if (messageModel.value?.conversationType === TUIChatEngine.TYPES.CONV_GROUP) {
            return 'tip';
        }
    }
    return '';
});

// 以下为messageTool等外部交互使用，无需特殊处理，勿动
const resendMessage = (message: IMessageModel) => {
    emits('resendMessage', message);
};
const handleToggleMessageItem = (e: any, message: IMessageModel, isLongpress = false) => {
    emits('handleToggleMessageItem', e, message, isLongpress);
};
const handleH5LongPress = (e: any, message: IMessageModel, type: string) => {
    emits('handleH5LongPress', e, message, type);
};
</script>

<template>
    <div class="message-plugin">
        <template v-if="isCustomerServicePluginMessage(messageModel)">
            <messageBubble
                v-if="!isMessageInvisible(messageModel)"
                :message-item="messageModel"
                :blink-message-i-d-list="props.blinkMessageIDList"
                @resend-message="resendMessage(messageModel)"
            >
                <template #messageElement>
                    <TUICustomerServicePlugin :message="messageModel" />
                </template>
            </messageBubble>
        </template>
        <template v-else>
            <!-- 以下为以messageTip形式展示 -->
            <div
                v-if="showStyle === 'tip'"
                class="message-plugin-tip"
            >
                <MessageCallGroup
                    :message="messageModel"
                    :signaling-info="messageSignalingInfo"
                    :custom-content="messageCustomContent"
                    :blink-message-i-d-list="props.blinkMessageIDList"
                ></MessageCallGroup>
            </div>
            <!-- 以下为以messageBubble形式展示 -->
            <div
                v-else-if="showStyle === 'bubble'"
                class="message-plugin-bubble-content"
                @longpress="handleToggleMessageItem($event, messageModel, true)"
                @click.prevent.right="handleToggleMessageItem($event, messageModel)"
                @touchstart="handleH5LongPress($event, messageModel, 'touchstart')"
                @touchend="handleH5LongPress($event, messageModel, 'touchend')"
                @mouseover="handleH5LongPress($event, messageModel, 'touchend')"
            >
                <messageBubble
                    :message-item="messageModel"
                    :blink-message-i-d-list="props.blinkMessageIDList"
                    @resend-message="resendMessage(messageModel)"
                >
                    <template #messageElement>
                        <MessageCallC2C
                            :message="messageModel"
                            :signaling-info="messageSignalingInfo"
                            :custom-content="messageCustomContent"
                        ></MessageCallC2C>
                    </template>
                </messageBubble>
            </div>
        </template>
    </div>
</template>
<style lang="scss" scoped>
.message-plugin-tip {
    margin: 0 auto;
    color: #999999;
    font-size: 12px;
    width: -webkit-fill-available;
    overflow-wrap: anywhere;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 10px 10px 10px;
}
.message-tip-highlight {
    animation: highlight 1000ms infinite;
    @-webkit-keyframes highlight {
        50% {
            color: #ff9c19;
        }
    }
    @keyframes highlight {
        50% {
            color: #ff9c19;
        }
    }
}
</style>
