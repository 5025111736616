import type { RouteRecordRaw } from 'vue-router';

const moduleName = 'session';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/session',
        name: moduleName,
        component: () => import('@/views/session/index.vue'),
        meta: {
            title: '当前会话',
            icon: 'zd-dangqianhuihua',
            badge: true
        }
    }
];

export default routes;
