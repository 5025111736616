const TUIChat = {
    '【安全提示】本 APP 仅用于体验腾讯云即时通信 IM 产品功能，不可用于业务洽谈与拓展。请勿轻信汇款、中奖等涉及钱款的信息，勿轻易拨打陌生电话，谨防上当受骗。':
        '【安全提示】本 APP 仅用于体验腾讯云即时通信 IM 产品功能，不可用于业务洽谈与拓展。请勿轻信汇款、中奖等涉及钱款的信息，勿轻易拨打陌生电话，谨防上当受骗。',
    '按Enter发送，Ctrl+Enter换行': '按Enter发送，Ctrl+Enter换行',
    表情: '[表情]',
    查看更多: '查看更多',
    撤回: '撤回',
    撤回了一条消息: '撤回了一条消息',
    打开: '打开',
    单击下载: '单击下载',
    点此投诉: '点此投诉',
    点击处理: '点击处理',
    对方正在输入: '对方正在输入...',
    发起群视频: '发起群视频',
    发起群语音: '发起群语音',
    发起通话: '发起通话',
    发送失败: '发送失败',
    复制: '复制',
    该消息不存在: '该消息不存在',
    关闭阅读状态: '关闭',
    管理员开启全员禁言: '管理员开启全员禁言',
    欢迎使用TUICallKit: '欢迎使用腾讯云音视频通话组件TUICallKit ',
    回到最新位置: '回到最新位置',
    回复: '回复',
    回复详情: '回复详情',
    集成TUICallKit: '集成 TUICallKit ',
    '检测到您暂未集成TUICallKit，无法体验音视频通话功能':
        '检测到您暂未集成TUICallKit，无法体验音视频通话功能。',
    结束群聊: '结束群聊',
    进行体验: '进行体验。',
    经度: '经度',
    拒绝: '拒绝',
    拒绝通话: '拒绝通话',
    开通腾讯实时音视频服务: '开通腾讯实时音视频服务',
    聊天记录: '[聊天记录]',
    描述: '描述',
    内容包含敏感词汇: '内容包含敏感词汇',
    您: '您',
    您当前购买使用的套餐包暂未开通此功能: '您当前购买使用的套餐包暂未开通此功能。',
    您当前购买使用的套餐包暂未开通群消息已读回执功能:
        '您当前购买使用的套餐包暂未开通【群消息已读回执】功能，请升级至旗舰版进行体验',
    您当前购买使用的套餐包暂未开通在线用户列表功能:
        '您当前购买使用的套餐包暂未开通【在线用户列表】功能，请升级至旗舰版进行体验',
    您已被管理员禁言: '您已被管理员禁言',
    您有: '您有',
    请点击: '请点击',
    请输入消息: '请输入消息',
    取消: '取消',
    取消通话: '取消通话',
    全部已读: '全部已读',
    确定: '确定',
    '确认重发该消息？': '确认重发该消息？',
    人关闭阅读状态: '人关闭阅读状态',
    人未读: '人未读',
    人已读: '人已读',
    删除: '删除',
    涉及敏感内容: '涉及敏感内容',
    申请加入: '申请加入',
    视频: '[视频]',
    视频通话: '视频通话',
    条回复: '条回复',
    条入群申请: '条入群申请',
    条新消息: '条新消息',
    通话时长: '通话时长',
    同意: '同意',
    图片: '[图片]',
    '图片消息失败,无效的图片格式': '图片消息失败,无效的图片格式',
    纬度: '纬度',
    未读: '未读',
    位置: '[位置]',
    文件: '[文件]',
    '文件不存在,请检查文件路径是否正确': '文件不存在,请检查文件路径是否正确',
    '文件大小超出了限制,如果上传文件,最大限制是100MB':
        '文件大小超出了限制,如果上传文件,最大限制是100MB',
    '文件消息失败,禁止发送违规封禁的文件': '文件消息失败,禁止发送违规封禁的文件',
    我: '我',
    无法查看: '无法查看',
    无法定位到原消息: '无法定位到原消息',
    无法收听: '无法收听',
    正在加载: '正在加载...',
    加载结束: '加载结束',
    无应答: '无应答',
    系统消息: '系统消息',
    消息: 'message',
    '消息或者资料中文本存在敏感内容,发送失败': '消息或者资料中文本存在敏感内容,发送失败',
    消息详情: '消息详情',
    '消息长度超出限制,消息长度不要超过12K': '消息长度超出限制,消息长度不要超过12K',
    '消息中图片存在敏感内容,发送失败': '消息中图片存在敏感内容,发送失败',
    选择提醒的人: '选择提醒的人',
    已读: '已读',
    已过撤回时限: '已过撤回时限',
    已接听: '已接听',
    已拒绝: '已拒绝',
    已同意: '已同意',
    引用: '引用',
    引用失败: '引用失败',
    语音: '[语音]',
    语音通话: '语音通话',
    重新编辑: '重新编辑',
    重新发送: '重新发送',
    转发: '转发',
    转发给: '转发给',
    自定义: '[自定义消息]',
    自定义消息: '自定义消息',
    '腾讯云 IM': '腾讯云 IM',
    空: '空',
    文本包含本地审核拦截词: '文本包含本地审核拦截词',
    按住说话: '按住说话',
    抬起发送: '抬起发送',
    抬起取消: '抬起取消',
    正在录音: '正在录音',
    继续上滑可取消: '继续上滑可取消',
    '松开手指 取消发送': '松开手指 取消发送',
    此机型暂不支持复制功能: '此机型暂不支持复制功能'
};

export default TUIChat;
