import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { useUserStore } from '@/store/modules/user';

type AuthKey = string | string[];
export function usePermission() {
    const checkKey = (authKeyString: string, permission: string) => {
        return !!(permission && permission && permission == authKeyString);
    };
    const hasPermission = (authKey: AuthKey): boolean => {
        if (!authKey || authKey.length == 0) {
            return true;
        }
        const userStore = useUserStore();
        const permissionList: string[] = [...(userStore.permissions || [])];

        for (const i in permissionList) {
            const item = permissionList[i];

            if (typeof authKey == 'string') {
                if (checkKey(authKey, item)) {
                    return true;
                }
            } else {
                for (const i in authKey) {
                    if (checkKey(authKey[i], item)) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    const accessRouter = (route: RouteLocationNormalized | RouteRecordRaw) => {
        if (!route.meta?.authKey || route.meta?.ignoreAuth) {
            return true;
        }

        return hasPermission(route.meta?.authKey);
    };

    return {
        hasPermission,
        accessRouter
    };
}
