<script lang="ts" setup>
import type { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { basicEmojiList, basicEmojiMap, basicEmojiUrl } from '../../utils/emojiList';

interface IProps {
    content: Record<string, any>;
    messageItem?: IMessageModel;
}

const props = withDefaults(defineProps<IProps>(), {
    content: () => ({})
});

const data = ref();
watchEffect(() => {
    data.value = props.content;
});

// 替换文本中的url为a标签
const replaceUrl = (text: string) => {
    const emojiReg = /(\[([^\]]+)\])/g;
    text = text.replace(emojiReg, (match, p1) => {
        const emoji = basicEmojiList.find((emoji: any) => emoji === p1);
        if (!emoji) return match;
        const url = basicEmojiUrl + basicEmojiMap[emoji];
        return `<img src="${url}" alt="${emoji}" style="width: 20px;height: 20px;" />`;
    });

    return text;
};
</script>

<template>
    <div>
        <span
            class="text-box"
            v-html="replaceUrl(props.messageItem?.payload.text)"
        />
        <!-- <span
            v-for="(item, index) in data.text"
            :key="index"
        >
            <span
                v-if="item.name === 'text'"
                class="text"
                >{{ item.text }}</span
            >
            <img
                v-else
                class="emoji"
                :src="item.src"
            />
        </span> -->
    </div>
</template>
<style lang="scss" scoped>
.text-img {
    width: 20px;
    height: 20px;
}
.text-box {
    white-space: pre-wrap;
    font-size: inherit;
    word-break: break-word;
    font-size: 14px;
    text-size-adjust: none;
    vertical-align: middle;
}
</style>
