<script lang="ts" setup>
import type { IMessageModel, IConversationModel } from '@tencentcloud/chat-uikit-engine';
import {
    TUIChatEngine,
    TUIStore,
    StoreName,
    TUITranslateService,
    TUIChatService,
    TUIGroupService
} from '@tencentcloud/chat-uikit-engine';

import throttle from 'lodash/throttle';

import MessageText from './message-elements/message-text.vue';
import MessageImage from './message-elements/message-image.vue';
import MessageAudio from './message-elements/message-audio.vue';
import MessageFile from './message-elements/message-file.vue';
import MessageFace from './message-elements/message-face.vue';
import MessageCustom from './message-elements/message-custom.vue';
import MessageTip from './message-elements/message-tip.vue';
import MessageBubble from './message-elements/message-bubble.vue';
import MessageLocation from './message-elements/message-location.vue';
import MessageTimestamp from './message-elements/message-timestamp.vue';
import MessageVideo from './message-elements/message-video.vue';
import MessageTool from './message-tool/index.vue';
import MessageRevoked from './message-tool/message-revoked.vue';
import ScrollButton from './scroll-button/index.vue';
import MessageGroupSystem from './message-elements/message-group-system.vue';
import ProgressMessage from '@/TUIKit/components/common/ProgressMessage/index.vue';
import ImagePreviewer from '@/TUIKit/components/common/ImagePreviewer/index.vue';
import Dialog from '@/TUIKit/components/common/Dialog/index.vue';
import MessagePlugin from '@/TUIKit/plugins/plugin-components/message-plugin.vue';
import {
    isEnabledMessageReadReceiptGlobal,
    isCreateGroupCustomMessage,
    isCustomSystemMessage
} from '@/views/session/components/chat/utils/utils';
import type { IGroupApplicationListItem } from '@/TUIKit/interface';
import { getBoundingClientRect, getScrollInfo } from '@/TUIKit/utils/universal-api/domOperation';
import { isPC } from '@/TUIKit/utils/env';
import { isPluginMessage } from '@/TUIKit/plugins/plugin-components/index';

interface ScrollConfig {
    scrollToMessage?: IMessageModel;
    scrollToBottom?: boolean;
    scrollToOffset?: {
        top?: number;
        bottom?: number;
    };
}

const emits = defineEmits(['handleEditor']);
const props = defineProps({
    groupID: {
        type: String,
        default: ''
    },
    isGroup: {
        type: Boolean,
        default: false
    }
});
// 表情回应相关
let observer: IntersectionObserver | null = null;
let groupType: string | undefined;
const sentReceiptMessageIDSet = new Set<string>();
const messageListRef = ref<HTMLElement>();
// 上屏展示 messageList，不包含 isDeleted 为 true 的 message
const messageList = ref<Array<IMessageModel>>();
// 所有 messageList 序列，包含 isDeleted 为 true 的 message
const allMessageList = ref<Array<IMessageModel>>();
const isCompleted = ref(false);
const currentConversationID = ref('');
const currentLastMessage = ref<IMessageModel>();
const currentLastMessageTime = ref<number>(0);
const nextReqMessageID = ref();
const toggleID = ref('');
const TYPES = ref(TUIChatEngine.TYPES);
const isLongpressing = ref(false);
const groupApplicationCount = ref(0);
const showGroupApplication = ref(false);
const applicationUserIDList = ref<Array<string>>([]);
const messageTarget = ref<IMessageModel>();
const messageElementListRef = ref<Array<HTMLElement> | null>();
const targetMessageDom = ref<HTMLElement | null>();
const blinkMessageIDList = ref<string[]>([]);
const scrollButtonInstanceRef = ref<InstanceType<typeof ScrollButton>>();
const isShowReadUserStatusPanel = ref<boolean>(false);
const readStatusMessage = ref<IMessageModel>();
const beforeHistoryGetScrollHeight = ref<number>(0);
const isTopMessageDom = ref<boolean>(false);

// 图片预览相关
const showImagePreview = ref(false);
const currentImagePreview = ref<IMessageModel>();
const imageMessageList = computed(() =>
    messageList?.value?.filter((item: IMessageModel) => {
        return !item.isRevoked && !item.hasRiskContent && item.type === TYPES.value.MSG_IMAGE;
    })
);

// 消息重发 Dialog
const reSendDialogShow = ref(false);
const resendMessageData = ref();

// 事件监听
onMounted(() => {
    // 消息 messageList
    TUIStore.watch(StoreName.CHAT, {
        messageList: onMessageListUpdated,
        messageSource: onMessageSourceUpdated,
        isCompleted: isCompletedUpdated
    });

    // 当前 ConversationID
    TUIStore.watch(StoreName.CONV, {
        currentConversationID: onCurrentConversationIDUpdated
    });

    // 群未决消息列表
    TUIStore.watch(StoreName.GRP, {
        groupSystemNoticeList: onGroupSystemNoticeList
    });

    // 群未决消息数量 | 关闭消息操作 popMenu
    TUIStore.watch(StoreName.CUSTOM, {
        groupApplicationCount: groupApplicationCountUpdated,
        isShowMessagePopMenu: isShowMessagePopMenuUpdated
    });
});

// 绑定监听滚动事件 展示scroll-button
onMounted(() => {
    messageListRef.value?.addEventListener('scroll', handelScrollListScroll);
});

// 取消监听
onUnmounted(() => {
    TUIStore.unwatch(StoreName.CHAT, {
        messageList: onMessageListUpdated,
        messageSource: onMessageSourceUpdated,
        isCompleted: isCompletedUpdated
    });

    TUIStore.unwatch(StoreName.CONV, {
        currentConversationID: onCurrentConversationIDUpdated,
        currentConversation: onCurrentConversationUpdated
    });

    TUIStore.unwatch(StoreName.GRP, {
        groupSystemNoticeList: onGroupSystemNoticeList
    });

    messageListRef.value?.removeEventListener('scroll', handelScrollListScroll);

    sentReceiptMessageIDSet.clear();
    observer?.disconnect();
    observer = null;
});

async function onMessageListUpdated(list: Array<IMessageModel>) {
    observer?.disconnect();
    const oldLastMessage = currentLastMessage.value;
    let hasEmojiReaction = false;
    allMessageList.value = list;
    messageList.value = list.filter(message => {
        // if (message.reactionList?.length && !message.isDeleted) {
        //     hasEmojiReaction = true;
        // }
        return !message.isDeleted;
    });
    if (!messageList.value?.length) {
        currentLastMessage.value = {};
        return;
    }
    const newLastMessage = messageList.value?.[messageList.value?.length - 1];
    if (messageTarget.value) {
        if (
            messageList.value?.findIndex(
                (message: IMessageModel) => message?.ID === messageTarget.value?.ID
            ) >= 0
        ) {
            const tempMessage = messageTarget.value;
            messageTarget.value = undefined;
            await scrollToPosition({ scrollToMessage: tempMessage });
            await blinkMessage(tempMessage?.ID);
        }
    } else if (beforeHistoryGetScrollHeight.value) {
        await scrollToPosition({
            scrollToOffset: { bottom: beforeHistoryGetScrollHeight.value }
        });
        beforeHistoryGetScrollHeight.value = 0;
    } else if (
        newLastMessage?.ID &&
        JSON.stringify(oldLastMessage) !== JSON.stringify(newLastMessage)
    ) {
        await scrollToPosition({ scrollToBottom: true });
    } else if (hasEmojiReaction && isCurrentListInBottomPosition()) {
        await scrollToPosition({ scrollToBottom: true });
    }
    currentLastMessage.value = Object.assign({}, newLastMessage);
    if (isEnabledMessageReadReceiptGlobal()) {
        nextTick(() => bindIntersectionObserver());
    }
}

function isCurrentListInBottomPosition() {
    return (
        messageListRef.value &&
        typeof messageListRef.value.scrollTop === 'number' &&
        typeof messageListRef.value.scrollHeight === 'number' &&
        typeof messageListRef.value.clientHeight === 'number' &&
        Math.ceil(messageListRef.value.scrollTop + messageListRef.value.clientHeight) >=
            messageListRef.value.scrollHeight
    );
}

async function scrollToPosition(config: ScrollConfig = {}): Promise<void> {
    return new Promise((resolve, reject) => {
        requestAnimationFrame(() => {
            if (!messageListRef.value) {
                reject();
            }
            const container = messageListRef.value;
            if (config.scrollToBottom) {
                container!.scrollTop = container!.scrollHeight;
            } else if (config.scrollToMessage) {
                const targetMessageDom = messageElementListRef.value?.find(
                    (dom: HTMLElement) => dom?.id === `tui-${config.scrollToMessage?.ID}`
                );
                if (targetMessageDom?.scrollIntoView) {
                    targetMessageDom.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
            } else if (config.scrollToOffset) {
                if (config.scrollToOffset?.top) {
                    container!.scrollTop = config.scrollToOffset.top;
                } else if (config.scrollToOffset?.bottom) {
                    container!.scrollTop = container!.scrollHeight - config.scrollToOffset.bottom;
                }
            }
            resolve();
        });
    });
}

async function onMessageSourceUpdated(message: IMessageModel) {
    // messageSource change 有两种情况
    // 1. messageSource change -> 未命中缓存 -> messageList change,
    // 2. messageSource change -> 命中缓存 -> messageList not change
    // 只有第二种情况需要在此处监听时增加 scrollToTarget
    messageTarget.value = message;
    if (messageTarget.value) {
        if (
            messageList.value?.findIndex(
                (message: IMessageModel) => message?.ID === messageTarget.value?.ID
            ) >= 0
        ) {
            const tempMessage = messageTarget.value;
            messageTarget.value = undefined;
            await scrollToPosition({ scrollToMessage: tempMessage });
            await blinkMessage(tempMessage?.ID);
        }
    }
}

function isCompletedUpdated(flag: boolean) {
    isCompleted.value = flag;
}

function groupApplicationCountUpdated(count: number) {
    groupApplicationCount.value = count;
}

function isShowMessagePopMenuUpdated(isShow: boolean) {
    if (!isShow) {
        toggleID.value = '';
    }
}

// 监听回调函数
const onCurrentConversationIDUpdated = (conversationID: string) => {
    currentConversationID.value = conversationID;
    messageList.value = [];
    // 获取加群系统消息列表
    TUIGroupService.getGroupApplicationList().then((res: any) => {
        const applicationList = res.data.applicationList.filter(
            (application: any) => application.groupID === props.groupID
        );
        applicationUserIDList.value = applicationList.map(
            (application: IGroupApplicationListItem) => {
                return application.applicationType === 0
                    ? application.applicant
                    : application.userID;
            }
        );
        TUIStore.update(StoreName.CUSTOM, 'groupApplicationCount', applicationList.length);
    });

    // 开启已读回执的状态 群聊缓存群类型
    if (isEnabledMessageReadReceiptGlobal()) {
        const { groupProfile } = TUIStore.getConversationModel(conversationID) || {};
        groupType = groupProfile?.type;
    }
};

const onCurrentConversationUpdated = (conversation: IConversationModel) => {
    currentLastMessageTime.value = conversation?.lastMessage?.lastTime || 0;
};

// operationType 操作类型 1: 有用户申请加群   23: 普通群成员邀请用户进群
const onGroupSystemNoticeList = (list: Array<IMessageModel>) => {
    const systemNoticeList = list.filter(message => {
        const { operationType } = message.payload;
        return (operationType === 1 || operationType === 23) && message.to === props.groupID;
    });

    systemNoticeList.forEach(systemNotice => {
        const { operationType } = systemNotice.payload;
        if (operationType === 1) {
            const { operatorID } = systemNotice.payload;
            if (!applicationUserIDList.value.includes(operatorID)) {
                applicationUserIDList.value.push(operatorID);
            }
        }
        if (operationType === 23) {
            const { inviteeList } = systemNotice.payload;
            inviteeList.forEach((invitee: string) => {
                if (!applicationUserIDList.value.includes(invitee)) {
                    applicationUserIDList.value.push(invitee);
                }
            });
        }
    });
    const applicationCount = applicationUserIDList.value.length;
    TUIStore.update(StoreName.CUSTOM, 'groupApplicationCount', applicationCount);
};

const toggleApplicationList = () => {
    showGroupApplication.value = !showGroupApplication.value;
};

const handleGroupApplication = (userID: string) => {
    const index = applicationUserIDList.value.indexOf(userID);
    if (index !== -1) {
        applicationUserIDList.value.splice(index, 1);
    }
};

// 获取历史消息
const getHistoryMessageList = () => {
    TUIChatService.getMessageList().then((res: any) => {
        const { nextReqMessageID: ID } = res.data;
        nextReqMessageID.value = ID;
    });
    // 获取历史消息后，保持滚动条在原来位置
    beforeHistoryGetScrollHeight.value = messageListRef.value?.scrollHeight;
};

const openComplaintLink = (type: any) => {
    window.open(type.url);
};

// 图片预览
const handleImagePreview = (message: IMessageModel) => {
    if (showImagePreview.value || currentImagePreview.value || isLongpressing.value) {
        return;
    }
    showImagePreview.value = true;
    currentImagePreview.value = message;
};
// 关闭图片预览
const onImagePreviewerClose = () => {
    showImagePreview.value = false;
    currentImagePreview.value = null;
};

// 消息操作
const handleToggleMessageItem = (e: any, message: IMessageModel, isLongpress = false) => {
    if (isLongpress) {
        isLongpressing.value = true;
    }
    toggleID.value = message.ID;
    filterTopMessageDom(e.target);
};

const handleToggleMessageItemForPC = (e: MouseEvent, message: IMessageModel) => {
    toggleID.value = message.ID;
    targetMessageDom.value = messageElementListRef.value?.find(
        (dom: HTMLElement) => dom?.id === `tui-${message.ID}`
    );
    onClickOutside(targetMessageDom.value);
    filterTopMessageDom(e.target);
};

// 判断 chat 可视区域顶部的 message 元素
function filterTopMessageDom(toggleMessageElement: any) {
    // 获取元素
    const chatElement = document.getElementById('TUIChat-main');
    const safeTop = 160; // 防止被安全提示遮挡
    const messageElementRect = toggleMessageElement.getBoundingClientRect(); // 当前 message 距离可视窗口的信息
    const ChatElementRect = chatElement.getBoundingClientRect();
    isTopMessageDom.value = messageElementRect.top - ChatElementRect.top < safeTop ? true : false;
}

// h5 long press
let timer: number;

// 消息撤回后，编辑消息
const handleEdit = (message: IMessageModel) => {
    emits('handleEditor', message, 'reedit');
};

// 重发消息
const resendMessage = (message: IMessageModel) => {
    reSendDialogShow.value = true;
    resendMessageData.value = message;
};

const resendMessageConfirm = () => {
    reSendDialogShow.value = !reSendDialogShow.value;
    const messageModel = resendMessageData.value;
    messageModel.resendMessage();
};

function blinkMessage(messageID: string): Promise<void> {
    return new Promise(resolve => {
        const index = blinkMessageIDList.value.indexOf(messageID);
        if (index < 0) {
            blinkMessageIDList.value.push(messageID);
            let timer = setTimeout(() => {
                blinkMessageIDList.value.splice(blinkMessageIDList.value.indexOf(messageID), 1);
                clearTimeout(timer);
                resolve();
            }, 3000);
        }
    });
}

// 滚动到最新消息
async function scrollToLatestMessage() {
    const { scrollHeight } = await getScrollInfo('#messageScrollList');
    const { height } = await getBoundingClientRect('#messageScrollList');

    if (messageListRef.value) {
        messageListRef.value?.scrollTo({
            top: scrollHeight - height,
            behavior: 'smooth'
        });
    }
}

const handelScrollListScroll = throttle(
    function (e: Event) {
        scrollButtonInstanceRef.value?.judgeScrollOverOneScreen(e);
    },
    150,
    { leading: true }
);

async function bindIntersectionObserver() {
    if (messageList.value.length === 0) {
        return;
    }

    if (groupType === TYPES.value.GRP_AVCHATROOM || groupType === TYPES.value.GRP_COMMUNITY) {
        // 直播群以及社群不进行消息的已读回执监听
        return;
    }

    const mappingFromIDToMessage: Record<
        string,
        {
            msgDom: HTMLElement;
            msgModel: IMessageModel | undefined;
        }
    > = {};

    observer?.disconnect();
    observer = new IntersectionObserver(
        (entries, _) => {
            entries.forEach(entry => {
                const { isIntersecting, target } = entry;
                if (isIntersecting) {
                    const { msgDom, msgModel } = mappingFromIDToMessage[target.id];
                    if (
                        msgModel &&
                        !msgModel.readReceiptInfo?.isPeerRead &&
                        !sentReceiptMessageIDSet.has(msgModel.ID)
                    ) {
                        TUIChatService.sendMessageReadReceipt([msgModel]);
                        sentReceiptMessageIDSet.add(msgModel.ID);
                        observer?.unobserve(msgDom);
                    }
                }
            });
        },
        {
            root: messageListRef.value,
            threshold: 0.7
        }
    );

    const arrayOfMessageLi = messageListRef.value?.querySelectorAll('.message-li');
    if (arrayOfMessageLi) {
        for (let i = 0; i < arrayOfMessageLi?.length; ++i) {
            const messageElement = arrayOfMessageLi[i] as HTMLElement;
            const matchingMessage = messageList.value.find((message: IMessageModel) => {
                return messageElement.id.slice(4) === message.ID;
            });
            if (
                matchingMessage &&
                matchingMessage.needReadReceipt &&
                matchingMessage.flow === 'in'
            ) {
                mappingFromIDToMessage[messageElement.id] = {
                    msgDom: messageElement,
                    msgModel: matchingMessage
                };
                observer?.observe(messageElement);
            }
        }
    }
}

function closeChatPop() {
    toggleID.value = '';
}

// 全局搜索dialog-点击外侧关闭
// click outside
let clickOutside = false;
let clickInner = false;
function onClickOutside(component: any) {
    document.addEventListener('mousedown', onClickDocument);
    component?.addEventListener && component?.addEventListener('mousedown', onClickTarget);
}

function removeClickListener(component: any) {
    document.removeEventListener('mousedown', onClickDocument);
    component?.removeEventListener && component?.removeEventListener('mousedown', onClickTarget);
}

function onClickDocument() {
    clickOutside = true;
    if (!clickInner && clickOutside) {
        toggleID.value = '';
        removeClickListener(targetMessageDom.value);
    }
    clickOutside = false;
    clickInner = false;
}

function onClickTarget() {
    clickInner = true;
}

onActivated(async () => {
    await scrollToPosition({ scrollToBottom: true });
});

// onMounted(async () => {
//     setTimeout(async () => {
//         await scrollToPosition({ scrollToBottom: true });
//     }, 1000);
// });

defineExpose({
    scrollToBottom: () => {
        scrollToPosition({ scrollToBottom: true });
    }
});
</script>

<template>
    <div class="TUIChat">
        <div
            id="TUIChat-main"
            class="TUIChat-main"
            @click="closeChatPop"
        >
            <!-- 消息列表 -->
            <ul
                id="messageScrollList"
                ref="messageListRef"
                class="TUI-message-list"
            >
                <p
                    v-if="!isCompleted"
                    class="message-more !p-3"
                    @click="getHistoryMessageList"
                >
                    {{ TUITranslateService.t('TUIChat.查看更多') }}
                </p>
                <li
                    v-for="(item, index) in messageList"
                    :id="'tui-' + item.ID"
                    :key="item.ID"
                    ref="messageElementListRef"
                    class="message-li"
                >
                    <!-- 消息时间组件 -->
                    <MessageTimestamp
                        :curr-time="item.time"
                        :prev-time="index > 0 ? messageList[index - 1].time : 0"
                    >
                    </MessageTimestamp>
                    <div class="message-item">
                        <MessageTip
                            v-if="
                                item.type === TYPES.MSG_GRP_TIP || isCreateGroupCustomMessage(item)
                            "
                            :content="item.getMessageContent()"
                            :blink-message-i-d-list="blinkMessageIDList"
                            @blink-message="blinkMessage"
                        />

                        <!-- 自定义系统消息提示组件 -->
                        <div v-else-if="isCustomSystemMessage(item)">
                            <MessageTip :content="JSON.parse(item.payload.data)" />
                        </div>

                        <div
                            v-else-if="!item.isRevoked && !isPluginMessage(item)"
                            @longpress="handleToggleMessageItem($event, item, true)"
                            @click.prevent.right="handleToggleMessageItemForPC($event, item)"
                        >
                            <MessageBubble
                                :message-item="JSON.parse(JSON.stringify(item))"
                                :content="item.getMessageContent()"
                                :blink-message-i-d-list="blinkMessageIDList"
                                @resend-message="resendMessage(item)"
                                @blink-message="blinkMessage"
                            >
                                <template #messageElement>
                                    <MessageText
                                        v-if="item.type === TYPES.MSG_TEXT"
                                        :content="item.getMessageContent()"
                                        :message-item="item"
                                    />
                                    <ProgressMessage
                                        v-if="item.type === TYPES.MSG_IMAGE"
                                        :content="item.getMessageContent()"
                                        :message-item="item"
                                    >
                                        <MessageImage
                                            :content="item.getMessageContent()"
                                            :message-item="item"
                                            @preview-image="handleImagePreview"
                                        />
                                    </ProgressMessage>
                                    <ProgressMessage
                                        v-if="item.type === TYPES.MSG_VIDEO"
                                        :content="item.getMessageContent()"
                                        :message-item="item"
                                    >
                                        <MessageVideo
                                            :content="item.getMessageContent()"
                                            :message-item="item"
                                        />
                                    </ProgressMessage>
                                    <MessageAudio
                                        v-if="item.type === TYPES.MSG_AUDIO"
                                        :content="item.getMessageContent()"
                                        :message-item="item"
                                    />
                                    <ProgressMessage
                                        v-if="item.type === TYPES.MSG_FILE"
                                        :content="item.getMessageContent()"
                                        :message-item="item"
                                    >
                                        <MessageFile
                                            :content="item.getMessageContent()"
                                            :message-item="item"
                                        />
                                    </ProgressMessage>
                                    <MessageFace
                                        v-if="item.type === TYPES.MSG_FACE"
                                        :message-item="item"
                                        :content="item.getMessageContent()"
                                        :is-p-c="isPC"
                                    />
                                    <MessageLocation
                                        v-if="item.type === TYPES.MSG_LOCATION"
                                        :content="item.getMessageContent()"
                                    />
                                    <MessageCustom
                                        v-if="item.type === TYPES.MSG_CUSTOM"
                                        :content="item.getMessageContent()"
                                        :message-item="item"
                                    />
                                </template>
                                <template #TUIEmojiPlugin> </template>
                            </MessageBubble>
                        </div>
                        <MessagePlugin
                            v-else-if="!item.isRevoked && isPluginMessage(item)"
                            :message="item"
                            :blink-message-i-d-list="blinkMessageIDList"
                            @resend-message="resendMessage"
                            @handle-toggle-message-item="handleToggleMessageItem"
                        />
                        <MessageRevoked
                            v-else
                            :is-edit="item.type === TYPES.MSG_TEXT"
                            :message-item="item"
                            @message-edit="handleEdit(item)"
                        />
                        <MessageTool
                            v-if="item.ID === toggleID"
                            :class="[
                                'message-tool',
                                item.flow === 'out' ? 'message-tool-out' : 'message-tool-in',
                                isTopMessageDom ? 'message-tool-bottom' : ''
                            ]"
                            :message-item="item"
                        />
                    </div>
                </li>
            </ul>
            <!-- 滚动按钮 -->
            <ScrollButton
                ref="scrollButtonInstanceRef"
                @scroll-to-latest-message="scrollToLatestMessage"
            />
            <Dialog
                v-if="reSendDialogShow"
                class="resend-dialog"
                :show="reSendDialogShow"
                :is-h5="!isPC"
                :center="true"
                :is-header-show="isPC"
                @submit="resendMessageConfirm()"
                @update:show="e => (reSendDialogShow = e)"
            >
                <p class="delDialog-title">
                    {{ TUITranslateService.t('TUIChat.确认重发该消息？') }}
                </p>
            </Dialog>
            <!-- 图片预览 -->
            <ImagePreviewer
                v-if="showImagePreview"
                :current-image="currentImagePreview"
                :image-list="imageMessageList"
                @close="onImagePreviewerClose"
            ></ImagePreviewer>
            <!-- 加群申请系统消息 -->
            <MessageGroupSystem
                v-if="showGroupApplication"
                :group-i-d="groupID"
                @toggle-application-list="toggleApplicationList"
                @handle-group-application="handleGroupApplication"
            ></MessageGroupSystem>
        </div>
    </div>
</template>
<style lang="scss" scoped src="./style/index.scss"></style>
