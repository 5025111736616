<script lang="ts" setup>
import type { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { zsdxEmojiUrl } from '../../utils/emojiList';

const props = defineProps({
    content: {
        type: Object,
        default: () => ({})
    },
    messageItem: {
        type: Object,
        default: () => ({} as IMessageModel)
    },
    isPC: {
        type: Boolean,
        default: false
    }
});
const data = ref();
const skeleton: any = ref();
watchEffect(() => {
    data.value = props.content;
    if (!data.value) return;
    nextTick(() => {
        // todo 大小显示
    });
});

const emojiUrl = computed(() => {
    const { data, index } = props.messageItem.payload;
    switch (index) {
        // zsdx自定义表情包
        case 1:
            return `${zsdxEmojiUrl}${data}.png`;

        default:
            return props.content.url;
    }
});
</script>

<template>
    <div
        ref="skeleton"
        class="message-image"
    >
        <!-- todo 统一组件处理-->
        <img
            class="message-image"
            :src="emojiUrl"
        />
    </div>
</template>
<style lang="scss" scoped>
@import '@/TUIKit/assets/styles/common.scss';
.message-image {
    width: 80px;
    height: 80px;
}
</style>
