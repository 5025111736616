const message = {
    tip: {
        群成员: '群成员',
        加入群组: '加入群组',
        退出群组: '退出群组',
        被: '被',
        踢出群组: '踢出群组',
        成为管理员: '成为管理员',
        被撤销管理员: '被撤销管理员',
        被禁言: '被禁言',
        被取消禁言: '被取消禁言',
        群提示消息: '群提示消息',
        管理员: '管理员',
        开启全员禁言: '开启全员禁言',
        取消全员禁言: '取消全员禁言',
        成为新的群主: '成为新的群主',
        修改群名为: '修改群名为',
        发布新公告: '发布新公告',
        申请加入群组: '申请加入群组',
        成功加入群组: '成功加入群组',
        被拒绝: '被拒绝',
        你被管理员: '你被管理员',
        群: '群',
        解散: '解散',
        创建群: '创建群',
        邀请你加群: '邀请你加群',
        你退出群组: '你退出群组',
        你被: '你被',
        设置为群: '设置为群',
        的管理员: '的管理员',
        撤销群: '撤销群',
        的管理员身份: '的管理员身份',
        同意加群: '同意加群',
        拒接加群: '拒接加群',
        自定义群系统通知: '自定义群系统通知'
    },
    custom: {
        自定义消息: '自定义消息',
        通话时长: '通话时长',
        通话结束: '通话结束',
        切换语音通话: '切换语音通话',
        切换视频通话: '切换视频通话',
        发起通话: '发起通话',
        取消通话: '取消通话',
        已接听: '已接听',
        拒绝通话: '拒绝通话',
        无应答: '无应答',
        对本次服务评价: '对本次服务评价',
        '查看详情>>': '查看详情>>'
    }
};

export default message;
