import TUIChat from './TUIChat';
import TUIConversation from './TUIConversation';
import TUIGroup from './TUIGroup';
import TUIContact from './TUIContact';
import TUISearch from './TUISearch';

import message from './message';
import component from './component';
import time from './time';

import Words from './words';
import Evaluate from './evaluate';

const messages = {
    zh_cn: {
        取消: '取消',
        发送: '发送',
        系统通知: '系统通知',
        关闭: '关闭',
        确定: '确定',
        开始时间: '开始时间',
        结束时间: '结束时间',
        TUIChat,
        TUIConversation,
        TUIGroup,
        TUIContact,
        message,
        component,
        time,
        Evaluate,
        Words,
        TUISearch
    }
};

export default messages;
