import type { RouteRecordRaw } from 'vue-router';

const moduleName = 'sessionRecord';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/session-record',
        name: moduleName,
        component: () => import('@/views/session-record/index.vue'),
        meta: {
            title: '会话记录',
            icon: 'zd-huihuajilu'
        }
    }
];

export default routes;
