import { TUILogin } from '@tencentcloud/tui-core';
import { TUIChatEngine } from '@tencentcloud/chat-uikit-engine';
import { getAppVersion, getImSign } from '@/api/user';
import { useUserStore } from '@/store/modules/user';
import { storageAppVersion, storageIsLogin } from '@/composables/storage';

export const SDKAppID = 1600014820;

export async function initImSdk(fromLogin = false) {
    const userStore = useUserStore();

    if (!fromLogin) {
        // 获取app版本信息 和 登录状态
        storageAppVersion.value = (await getAppVersion()).version;
        // if (!storageIsLogin.value) await getSessionTicketRemote();

        // await userStore.updateLoginStatus();
    }

    if (storageIsLogin.value) {
        const { sign, identifier } = await getImSign();

        await userStore.getUserRoleMap();
        const loginInfo: any = {
            SDKAppID,
            userID: identifier,
            userSig: sign,
            useUploadPlugin: true,
            useProfanityFilterPlugin: true,
            framework: `vue3`
        };

        try {
            await TUILogin.login(loginInfo);
            TUIChatEngine.setLogLevel(3);
            await userStore.updateUserInfo();
        } catch (error) {
            console.log(error);
        }
    }
}
