import TUIChat from './TUIChat';
import TUIConversation from './TUIConversation';
import TUIGroup from './TUIGroup';
import TUIContact from './TUIContact';
import TUISearch from './TUISearch';

import message from './message';
import component from './component';
import time from './time';

import Words from './words';
import Evaluate from './evaluate';

const messages = {
    en: {
        取消: 'Cancel',
        发送: 'Send',
        系统通知: 'System notification',
        关闭: 'Close',
        确定: 'Save',
        开始时间: 'Start Date',
        结束时间: 'End Date',
        TUIChat,
        TUIConversation,
        TUIGroup,
        TUIContact,
        message,
        component,
        time,
        Evaluate,
        Words,
        TUISearch
    }
};

export default messages;
