<script setup lang="ts">
import { configProviderProps } from 'ant-design-vue/es/config-provider/context';
import zh_CN from 'ant-design-vue/es/locale/zh_CN';

defineOptions({
    name: 'ProConfigProvider'
});

const props = defineProps(configProviderProps());
</script>

<template>
    <ZdxConfigProvider
        v-bind="props"
        :locale="zh_CN"
        iconfont="https://nss1.zsdx.cn/frontend/iconfont/font_3881615_5n28uvnwubj.js"
    >
        <slot></slot>
    </ZdxConfigProvider>
</template>
