import TUIChat from './chat/index.vue';
import TUIConversation from './conversationList/index.vue';

const TUIComponents = {
    TUIChat,
    TUIConversation
};

export default TUIComponents;

export { TUIChat, TUIConversation };
