const TUIConversation = {
    发起会话: 'Start chat',
    发起单聊: 'New one-to-one chat',
    发起群聊: 'New group chat',
    请输入userID: 'Enter a user ID',
    删除会话: 'Delete',
    '删除后，将清空该聊天的消息记录': 'Once deleted, all chat messages will be cleared.',
    '删除后，将清空该群聊的消息记录': 'Once deleted, group chat history will be cleared.',
    系统通知: 'System notification',
    置顶会话: 'Pin',
    取消置顶: 'Unpin',
    消息免打扰: 'Mute Notifications',
    取消免打扰: 'Enable Notifications',
    '有人@我': 'Someone @ me',
    '@所有人': '@All',
    条: 'strip',
    我: 'me',
    '网络异常，请您检查网络设置': 'Network exception, please check the network settings',
    您已被群管理员移出群聊: 'You have been removed from the group chat by the group administrator',
    该群聊已被解散: 'The group chat has been disband',
    您已退出该群聊: 'You have left the group chat'
};

export default TUIConversation;
