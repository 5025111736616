import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', () => {
    const totalUnreadCount = ref(0);

    function updateTotalUnreadCount(count: number) {
        totalUnreadCount.value = count;
    }

    return {
        totalUnreadCount,
        updateTotalUnreadCount
    };
});
