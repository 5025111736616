const TUISearch = {
    搜索: 'Search',
    全部: 'All',
    联系人: 'Contacts',
    群聊: 'Groups',
    聊天记录: 'Chat History',
    文本: 'Text',
    文件: 'File',
    其他: 'Other',
    '图片/视频': 'Image/Video',
    选择时间: 'Select time',
    今天: 'Today',
    近三天: 'Nearly three days',
    近七天: 'Nearly seven days',
    查看更多: 'View more ',
    条相关: ' related ',
    条与: ' related to ',
    相关的: 'keywords',
    进入聊天: 'enter',
    定位到聊天位置: 'Navigate to the chat position',
    搜索会话内容: 'Search in conversation',
    查看更多历史记录: 'View more history message',
    暂无搜索结果: 'No Results',
    结果: 'results',
    '[文本]': '[Text Message]',
    '[图片]': '[Image Message]',
    '[语音]': '[Audio Message]',
    '[视频]': '[Video Message]',
    '[文件]': '[File Message]',
    '[自定义消息]': '[Custom Message]',
    '[系统消息]': '[System Message]',
    '[合并消息]': '[Merged Message]',
    '[位置消息]': '[Position Message]',
    '消息云端搜索失败：': 'Message cloud search failed: ',
    '查找联系人失败：': 'Failed to find contact: ',
    '查找群聊失败：': 'Failed to find group: ',
    进入会话失败: 'Failed to enter conversation'
};

export default TUISearch;
