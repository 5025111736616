import type { RouteRecordRaw } from 'vue-router';

const moduleName = 'account';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/account',
        name: moduleName,
        component: () => import('@/views/account/index.vue'),
        meta: {
            title: '个人信息',
            icon: 'zd-shouye',
            hideInMenu: true
        }
    }
];

export default routes;
