<script setup lang="ts">
import type { IConversationModel, IGroupModel } from '@tencentcloud/chat-uikit-engine';
import { StoreName, TUIConversationService, TUIStore } from '@tencentcloud/chat-uikit-engine';
import { Empty } from 'ant-design-vue';
import { watch } from 'vue';
import { useVModel } from '@vueuse/core';
import { actionMenuItems, handleMenuItemAction } from './constant';
import MuteIcon from './components/muteIcon/index.vue';
import Header from './components/header/index.vue';
import { getEndSession, reopenSession } from '@/api/session';
import db from '@/utils/db';
import { useAppStore } from '@/store/modules/app';

const props = defineProps<{
    type: number;
    activeKey: number;
}>();
const currentGroupId = ref<string>();
const status = ref(2);

const conversationList = ref<IConversationModel[]>([]);
const keyword = ref<string[]>(['', '']);
const emit = defineEmits({});
const groupList = ref<string[]>([]);
const appStore = useAppStore();

const tab = useVModel(props, 'activeKey', emit);

const selectConversation = async (conversation: IConversationModel) => {
    if (props.type === 1) {
        // try {
        //     await reopenSession({ groupId: conversation.groupProfile.groupID });
        // } catch (e) {
        //     console.error(e);
        // }
        TUIConversationService.switchConversation(conversation.conversationID);
    } else {
        await reopenSession({ groupId: conversation.groupProfile.groupID });
        TUIConversationService.switchConversation(`GROUP${conversation.groupProfile?.groupID}`);
        tab.value = 1;
    }
};

const watchOptions = {
    currentConversation: (conversation: IConversationModel) => {
        currentGroupId.value = conversation?.groupProfile?.groupID;
    },
    conversationList: async (list: IConversationModel[]) => {
        console.log('list', list);
        const filterList = list.filter(conversation =>
            // (conversation.groupProfile?.name.includes(keyword.value[props.type - 1]) ||
            //     !keyword.value[props.type - 1]) &&
            conversation.conversationID?.startsWith('GROUPcg_')
        );
        try {
            for (const conversation of filterList) {
                const data = await db.getGroupUserInfo(conversation.groupProfile?.groupID);
                conversation.getAvatar = () => data.headImg;
                conversation.getShowName = () => data.nickName;
            }
        } catch (e) {
            console.error(e);
        }

        conversationList.value = filterList;
        const unreadCount = conversationList.value.reduce(
            (total, conversation) => total + conversation.unreadCount,
            0
        );
        appStore.updateTotalUnreadCount(unreadCount);
        status.value = 2;
    }
};

TUIStore.watch(StoreName.GRP, {
    groupList: async (val: IGroupModel[]) => {
        const groupSet = new Set();

        for (const group of val) {
            groupSet.add(group.groupID);
        }

        if (groupList.value.length > groupSet.size) {
            for (const groupID of groupList.value) {
                if (!groupSet.has(groupID)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    await TUIConversationService.deleteConversation({
                        conversationIDList: [`GROUP${groupID}`],
                        clearHistoryMessage: false
                    });
                    await TUIConversationService.switchConversation('');
                }
            }
        }

        groupList.value = val.map(group => group.groupID);
    }
});

async function getOverConversation() {
    let _conversationList: IConversationModel[] = [];
    try {
        _conversationList = await getEndSession();
    } catch (e) {
        /** empty */
    } finally {
        conversationList.value = _conversationList;
        status.value = 2;
    }
}

const listen = () => {
    if (props.type === 1) {
        // TUIStore.watch(StoreName.CONV, watchOptions);
    } else {
        // TUIStore.unwatch(StoreName.CONV, watchOptions);
        getOverConversation();
    }
};

watch(
    () => props.activeKey,
    () => {
        if (props.activeKey === props.type) {
            listen();
        }
    },
    {
        immediate: true
    }
);

const conversationFilterList = computed(() => {
    return conversationList.value
        .filter(
            conversation =>
                conversation.getShowName()?.includes(keyword.value[props.type - 1]) ||
                !keyword.value[props.type - 1]
        )
        .filter(item => groupList.value.includes(item.groupProfile?.groupID) || props.type === 2);
});

const offline = () => {
    status.value = -1;
};

const online = () => {
    status.value = 2;
};

onMounted(async () => {
    window.addEventListener('offline', offline);
    window.addEventListener('online', online);

    if (props.type === 1) {
        TUIStore.watch(StoreName.CONV, watchOptions);
    }
});

onUnmounted(() => {
    window.removeEventListener('offline', offline);
    window.removeEventListener('online', online);
});
</script>

<template>
    <div class="h-full! bg-#fff flex flex-col conversation-list">
        <a-input-search
            v-model:value="keyword[type - 1]"
            class="px-5 box-border mt-1 mb-4"
            placeholder="搜索会话"
        />
        <Header :status="status" />
        <div class="flex-1 overflow-auto">
            <template v-if="conversationFilterList.length">
                <a-dropdown
                    v-for="conversation in conversationFilterList"
                    :key="conversation.groupProfile?.groupID"
                    :trigger="['contextmenu']"
                    destroy-popup-on-hide
                >
                    <div
                        class="px-4 h-15 flex items-center gap-2 hover:bg-#F5F9FA cursor-pointer"
                        :style="{
                            backgroundColor:
                                currentGroupId === conversation.groupProfile?.groupID ||
                                conversation.isPinned
                                    ? '#EDF1F2'
                                    : ''
                        }"
                        @click="selectConversation(conversation)"
                    >
                        <a-badge
                            :count="
                                conversation.unreadCount > 99 ? '99+' : conversation.unreadCount
                            "
                            :dot="conversation.isMuted"
                        >
                            <img
                                class="w-10 h-10 rounded-50%"
                                :src="conversation.getAvatar()"
                            />
                        </a-badge>
                        <div class="flex flex-col gap-1 flex-1">
                            <div class="flex justify-between items-center">
                                <div
                                    class="color-#000000D8 text-3.5 font-500 lh-5 line-clamp-1 break-all mr-2"
                                >
                                    {{ conversation.getShowName() }}
                                </div>
                                <div class="text-3 color-#00000073 shrink-0!">
                                    <template v-if="type === 1">
                                        {{ conversation.getLastMessage('time') }}
                                    </template>
                                </div>
                            </div>
                            <div class="flex justify-between items-center">
                                <div class="color-#000000A6 line-clamp-1 break-all mr-2">
                                    {{ conversation.lastMessage?.messageForShow }}
                                </div>
                                <MuteIcon :visible="conversation.isMuted" />
                            </div>
                        </div>
                    </div>
                    <template
                        v-if="type === 1"
                        #overlay
                    >
                        <a-menu>
                            <a-menu-item
                                v-for="item in actionMenuItems"
                                :key="item.value"
                                @click="handleMenuItemAction(item.value, conversation)"
                            >
                                {{ item.title(conversation) }}
                            </a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
            </template>
            <a-empty
                v-else
                :image="Empty.PRESENTED_IMAGE_SIMPLE"
            />
        </div>
    </div>
</template>

<style lang="less" scoped>
.conversation-list {
    :deep(.ant-input-search-button) {
        border-color: #d9d9d9 !important;
        border-left: none !important;

        .anticon-search {
            color: rgba(0, 0, 0, 0.25);
        }
    }

    :deep(.ant-input) {
        border-color: #d9d9d9 !important;
    }
}
</style>
