import type { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import {
    TUIChatEngine,
    StoreName,
    TUIConversationService,
    TUIStore
} from '@tencentcloud/chat-uikit-engine';
import dayjs from 'dayjs';
import router from '@/router';
import { CustomMessageType } from '@/enums/message';

const TYPES = TUIChatEngine.TYPES;

const defaultMessage = '您收到一条新消息';
function getCustomMessageForShow(message: IMessageModel) {
    const { payload } = message;
    const customData = JSON.parse(payload.data);

    switch (customData.type) {
        case CustomMessageType.GOODS:
            return '[商品卡片]';
        case CustomMessageType.ORDER:
            return '[订单消息]';
        default:
            return '';
    }
}

function getMessageForShow(message: IMessageModel) {
    switch (message.type) {
        case TYPES.MSG_TEXT:
            return message.payload.text;
        case TYPES.MSG_IMAGE:
            return '[图片]';
        case TYPES.MSG_AUDIO:
        case TYPES.MSG_SOUND:
            return '[语音]';
        case TYPES.MSG_VIDEO:
            return '[视频]';
        case TYPES.MSG_FILE:
            return '[文件]';
        case TYPES.MSG_FACE:
            return '[动画表情]';
        case TYPES.MSG_LOCATION:
            return '[位置消息]';
        case TYPES.MSG_MERGER:
            return '[合并消息]';
        case TYPES.MSG_CUSTOM:
            return getCustomMessageForShow(message);
        default:
            return defaultMessage;
    }
}

function safeParse(jsonString = '{}'): Record<string, any> {
    try {
        return JSON.parse(jsonString);
    } catch {
        return {};
    }
}

function isTypingMessage(message: IMessageModel): boolean {
    const { businessID } = safeParse(message.payload?.data);
    return businessID === 'user_typing_status';
}

function checkPageFocus(): boolean {
    const route = router.currentRoute;
    return document.hasFocus() && route.value.path === '/session';
}

function needShowNotification(message: IMessageModel) {
    if (
        !message ||
        !message.ID ||
        !message.type ||
        message.isRevoked ||
        message.isDeleted ||
        isTypingMessage?.(message)
    ) {
        return false;
    }

    if (message.type === TYPES.MSG_GRP_TIP || message.type === TYPES.MSG_GRP_SYS_NOTICE) {
        return false;
    }

    const currentConversationID = TUIStore.getData(StoreName.CONV, 'currentConversationID');

    if (checkPageFocus() && message?.conversationID === currentConversationID) {
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (message.isSystemMessage) return false;
    if (message.type === TYPES.MSG_CUSTOM && !getCustomMessageForShow(message)) return;

    if (message.type === 'TIMGroupTipElem') return;

    // 过滤掉自己发送的消息
    if (message.flow === 'out') return;

    if (message.time * 1000 < dayjs().startOf('day').unix()) return;

    return true;
}

export async function notification(message: IMessageModel) {
    if (!needShowNotification(message)) return;
    console.log('notification', message);

    const messageBody = getMessageForShow(message);
    const notifyInstance = new Notification(message.nick, {
        body: messageBody,
        icon: message.avatar
    });

    notifyInstance.onclick = async () => {
        if (!location.href.endsWith('/session')) {
            await router.push({
                name: 'session'
            });
        }

        await TUIConversationService.switchConversation(message.conversationID);
    };
}
