<script lang="ts" setup>
const props = defineProps({
    content: {
        type: Object,
        default: () => ({})
    }
});
const tipContent = computed(
    () => props.content?.text || props.content?.custom || props.content.content || ''
);
</script>

<template>
    <div class="message-tip">
        <span>{{ tipContent }}</span>
    </div>
</template>
<style lang="scss" scoped>
@import '@/TUIKit/assets/styles/common.scss';
.message-tip {
    margin: 0 auto;
    color: #999999;
    font-size: 12px;
    width: -webkit-fill-available;
    overflow-wrap: anywhere;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
    &-highlight {
        animation: highlight 1000ms infinite;
        @-webkit-keyframes highlight {
            50% {
                color: #ff9c19;
            }
        }
        @keyframes highlight {
            50% {
                color: #ff9c19;
            }
        }
    }
}
</style>
