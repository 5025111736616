const TUIGroup = {
    输入groupID搜索: '输入groupID搜索',
    群名称: '群名称',
    群ID: '群ID',
    加入群聊: '加入群聊',
    解散群聊: '解散群聊',
    退出群聊: '退出群聊',
    群类型: '群类型',
    请填写验证信息: '请填写验证信息',
    申请加入: '申请加入',
    已申请: '已申请',
    群公告: '群公告',
    群成员: '群成员',
    群管理: '群管理',
    好友工作群: '好友工作群',
    陌生人社交群: '陌生人社交群',
    临时会议群: '临时会议群',
    直播群: '直播群',
    社群: '社群',
    自由加入: '自由加入',
    需要验证: '需要验证',
    禁止加群: '禁止加群',
    人: '人',
    群头像: '群头像',
    加群方式: '加群方式',
    转让群组: '转让群组',
    退出群组: '退出群组',
    群管理员: '群管理员',
    全员禁言: '全员禁言',
    '全员禁言开启后，只允许群主和管理员发言。': '全员禁言开启后，只允许群主和管理员发言。',
    单独禁言人员: '单独禁言人员',
    删除成员: '删除成员',
    '确定从群聊中删除该成员？': '确定从群聊中删除该成员？',
    '确定从群聊中删除所选成员？': '确定从群聊中删除所选成员？',
    暂无公告: '暂无公告',
    发布: '发布',
    编辑: '编辑',
    查看更多: '查看更多',
    管理员: '管理员',
    群主: '群主',
    我: '我',
    确认: '确认',
    添加成员: '添加成员',
    新增管理员: '新增管理员',
    移除管理员: '移除管理员',
    新增禁言用户: '新增禁言用户',
    移除禁言用户: '移除禁言用户',
    修改群聊名称: '修改群聊名称',
    '修改群聊名称后，将在群内通知其他成员': '修改群聊名称后，将在群内通知其他成员',
    '仅限中文、字母、数字和下划线，2-20个字': '仅限中文、字母、数字和下划线，2-20个字',
    '请先注册 TUIGroup 模块': '请先注册 TUIGroup 模块',
    该用户不存在: '该用户不存在',
    该用户不在群组内: '该用户不在群组内',
    添加群聊: '添加群聊',
    该群组不存在: '该群组不存在',
    '创建群聊，请注册 TUIGroup 模块': '创建群聊，请注册 TUIGroup 模块',
    创建成功: '创建成功',
    '发起多人会话（群聊）': '发起多人会话（群聊）',
    选填: '选填',
    取消: '取消',
    创建: '创建',
    '类似普通微信群，创建后仅支持已在群内的好友邀请加群，且无需被邀请方同意或群主神奇。详见':
        '类似普通微信群，创建后仅支持已在群内的好友邀请加群，且无需被邀请方同意或群主神奇。详见',
    '类似 QQ 群，创建后群主可以指定群管理员，用户搜索群 ID 发起加群申请后，需要群主或管理员审批通过才能入群。详见':
        '类似 QQ 群，创建后群主可以指定群管理员，用户搜索群 ID 发起加群申请后，需要群主或管理员审批通过才能入群。详见',
    '创建后可以随意进出，且支持查看入群前消息；适合用于音视频会议场景、在线教育场景等与实时音视频产品结合的场景。详见':
        '创建后可以随意进出，且支持查看入群前消息；适合用于音视频会议场景、在线教育场景等与实时音视频产品结合的场景。详见',
    '创建后可以随意进出，没有群成员数量上限，但不支持历史消息存储；适合与直播产品结合，用于弹幕聊天场景。详见':
        '创建后可以随意进出，没有群成员数量上限，但不支持历史消息存储；适合与直播产品结合，用于弹幕聊天场景。详见',
    '创建后可以随意进出，最多支持100000人，支持历史消息存储，用户搜索群 ID 发起加群申请后，无需管理员审批即可进群。详见':
        '创建后可以随意进出，最多支持100000人，支持历史消息存储，用户搜索群 ID 发起加群申请后，无需管理员审批即可进群。详见',
    产品文档: '产品文档',
    设置群名称: '设置群名称',
    请输入群名称: '请输入群名称',
    设置群ID: '设置群ID',
    请输入群ID: '请输入群ID',
    选择群类型: '选择群类型',
    '陌生人社交群（Public）': '陌生人社交群（Public）',
    '临时会议群（Meeting）': '临时会议群（Meeting）',
    '好友工作群（Work）': '好友工作群（Work）',
    '直播群（AVChatroom）': '直播群（AVChatroom）',
    '社群（Community）': '社群（Community）',
    群组创建成功: '群组创建成功',
    群组解散成功: '群组解散成功',
    禁言设置成功: '禁言设置成功',
    取消禁言成功: '取消禁言成功',
    群名称不能为空: '群名称不能为空',
    群名称修改成功: '群名称修改成功',
    '群公告字数超出限制，最大长度为150': '群公告字数超出限制，最大长度为150'
};

export default TUIGroup;
