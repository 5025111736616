<script setup lang="ts">
import { CopyOutlined } from '@ant-design/icons-vue';
import { useClipboard } from '@vueuse/core';
import { message } from 'ant-design-vue';

const props = defineProps<{
    orderId: string;
    price: string;
    title: string;
    cover: string;
    num: number | string;
}>();

const { copy } = useClipboard();

const handleCopy = () => {
    copy(props.orderId);
    message.success('复制成功');
};
</script>

<template>
    <div class="w-84">
        <div class="flex gap-2">
            <div class="truncate">订单编号：{{ props.orderId }}</div>
            <CopyOutlined
                class="cursor-pointer color-primary"
                @click="handleCopy"
            />
        </div>
        <div class="flex-y-center gap-4 mt-2">
            <img
                class="w-76px h-76px flex flex-shrink-0"
                :src="props.cover"
                alt=""
            />
            <div class="flex-col gap-4 w-full">
                <div class="line-clamp-2">
                    {{ props.title }}
                </div>
                <div class="flex-bc">
                    <div>
                        <span>¥</span>
                        <span>{{ props.price }}</span>
                    </div>
                    <div class="text-gray">共{{ props.num }}件</div>
                </div>
            </div>
        </div>
    </div>
</template>
