<script lang="ts" setup>
import type { IConversationModel } from '@tencentcloud/chat-uikit-engine';
import { TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import ToolbarItemContainer from '../toolbar-item-container/index.vue';
import EmojiPickerDialog from './emoji-picker-dialog.vue';
import { isH5 } from '@/TUIKit/utils/env';
import faceIcon from '@/TUIKit/assets/icon/face.png';

const emits = defineEmits([
    'insertEmoji',
    'dialogShowInH5',
    'dialogCloseInH5',
    'currentComponentID',
    'toggleComponent',
    'sendMessage'
]);
const currentConversation = ref();
const container = ref();

TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
        currentConversation.value = conversation;
    }
});

const onIconClick = (dialogRef: any) => {
    if (!isH5) {
        emits('toggleComponent');
        return;
    }
    emits('dialogShowInH5', dialogRef.value);
};

const onDialogClose = (dialogRef: any) => {
    if (!isH5) {
        return;
    }
    emits('dialogCloseInH5', dialogRef.value);
};

const insertEmoji = (emojiObj: Object) => {
    emits('insertEmoji', emojiObj);
};
const sendMessage = () => {
    emits('sendMessage');
};
const onClose = () => {
    container?.value?.toggleDialogDisplay(false);
};
</script>
<template>
    <ToolbarItemContainer
        ref="container"
        :icon-file="faceIcon"
        title="表情"
        @on-icon-click="onIconClick"
        @on-dialog-close="onDialogClose"
    >
        <EmojiPickerDialog
            @insert-emoji="insertEmoji"
            @send-message="sendMessage"
            @on-close="onClose"
        />
    </ToolbarItemContainer>
</template>
<style lang="scss" scoped src="./style/index.scss"></style>
