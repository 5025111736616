<script setup lang="ts">
const props = defineProps<{
    welcomeConfig: string;
    faqConfigList: {
        faqId: string;
        keywords: string;
    }[];
}>();
</script>

<template>
    <div class="flex flex-col gap-3 max-w-100 lh-20px">
        <div>
            {{ props.welcomeConfig }}
        </div>

        <div
            v-if="props.faqConfigList && props.faqConfigList.length"
            class="flex flex-col gap-3"
        >
            <div
                v-for="item in props.faqConfigList"
                :key="item.faqId"
                class="text-primary"
            >
                {{ item.keywords }}
            </div>
        </div>

        <div class="text-gray">此消息由机器人发送</div>
    </div>
</template>
