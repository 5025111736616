const component = {
    取消: 'Cancel',
    完成: 'Done',
    已选中: 'Selected',
    人: '',
    保存: 'Save',
    请输入userID: 'Enter a userID',
    请输入groupID: 'Enter a groupID',
    确定: 'submit',
    全选: 'All',
    已在群聊中: 'exists',
    查看更多: 'More',
    '图片 url 不存在': 'Image url does not exist',
    已取消: 'Cancelled',
    获取权限失败: 'Failed to obtain permission',
    已保存至相册: 'Saved to album',
    图片下载失败: 'Image download failed',
    暂不支持下载此类型图片: 'Downloading this type of pictures is not supported yet'
};

export default component;
