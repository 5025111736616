<script setup lang="ts">
import type { IConversationModel } from '@tencentcloud/chat-uikit-engine';
import {
    TUIGroupService,
    TUIConversationService,
    StoreName,
    TUIStore
} from '@tencentcloud/chat-uikit-engine';
import type { PropType } from 'vue';
import { Empty, message } from 'ant-design-vue';
import { exchangeCustomerService, getSwitchCustomerServiceList } from '@/api/session';
import db from '@/utils/db';

const props = defineProps({
    conversation: {
        type: Object as PropType<IConversationModel>,
        default: () => ({})
    }
});
let currentGroupId = '';

const typingStatus = ref(false);
const loading = ref(true);
const currentConversation = ref<IConversationModel>(props.conversation);
const customerList = ref<SESSION_API.CustomerServiceInfo[]>([]);
const groupName = ref('');

const showName = computed(() => {
    return typingStatus.value ? '对方正在输入...' : groupName.value;
});

async function onDropdownOpenChange(open: boolean) {
    if (open) {
        try {
            loading.value = true;

            const { list } = await getSwitchCustomerServiceList();
            customerList.value = list;
        } finally {
            loading.value = false;
        }
    }
}

async function handleSwitchCustomerService(item: SESSION_API.CustomerServiceInfo) {
    try {
        await exchangeCustomerService({
            customerId: item.kfId,
            customerImId: item.kfImId,
            groupId: currentGroupId
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        await TUIConversationService.deleteConversation({
            conversationIDList: [currentConversation.value.conversationID],
            clearHistoryMessage: false
        });
        await TUIConversationService.switchConversation('');
        message.success('转接成功');
    } catch (error) {
        message.error('转接失败');
    }
}

TUIStore.watch(StoreName.CHAT, {
    typingStatus: (status: boolean) => {
        typingStatus.value = status;
    }
});

watch(
    () => props.conversation.conversationID,
    async () => {
        currentGroupId = props.conversation.groupProfile?.groupID;

        const info = await db.getGroupUserInfo(props.conversation.groupProfile?.groupID);

        groupName.value = info.nickName;
    },
    { immediate: true }
);
</script>

<template>
    <div class="h-46px px-4 shadow flex-bc">
        <div class="max-w-1/2 truncate font-500">
            {{ showName }}
        </div>
        <div>
            <a-dropdown
                trigger="click"
                placement="bottomRight"
                @open-change="onDropdownOpenChange"
            >
                <a-button type="link"> 转接 </a-button>
                <template #overlay>
                    <a-menu
                        v-if="customerList.length"
                        class="min-w-40"
                    >
                        <a-menu-item
                            v-for="item in customerList"
                            :key="item.kfId"
                            :disabled="item.kfOnline === 0"
                            @click="handleSwitchCustomerService(item)"
                        >
                            <a-badge
                                class="w-full text-left"
                                :status="item.kfOnline === 1 ? 'success' : 'default'"
                                :text="item.kfNick || item.kfName"
                            />
                        </a-menu-item>
                    </a-menu>
                    <a-empty
                        v-else
                        :image="Empty.PRESENTED_IMAGE_SIMPLE"
                        description="暂无客服"
                        class="w-40 h-30 flex-col flex-center"
                    />
                </template>
            </a-dropdown>
            <!--<a-divider type="vertical" />
            <a-button type="link"> 退出接待 </a-button>-->
        </div>
    </div>
</template>
