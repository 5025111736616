const Evaluate = {
    请对本次服务进行评价: 'Please rate this service',
    提交评价: 'Feedback',
    服务评价工具: 'Service rate tool',
    使用: 'Build with',
    自定义消息: 'Custom message',
    搭建: '',
    星: 'Star'
};

export default Evaluate;
