export enum CustomMessageType {
    /**商品卡片 */
    GOODS = 'SEND_GOODS_MESSAGE',
    /**订单消息 */
    ORDER = 'SEND_ORDER_MESSAGE',
    /**自动回复关键词消息 */
    AUTO_REPLY = 'SEND_SERVICE_GREET',
    /** 系统自定义消息 */
    SYSTEM = 'HU_CUSTOM_SYSTEM_MESSAGE'
}
