export const CHAT_MSG_CUSTOM_TYPE = {
    SERVICE: 'consultion',
    EVALUATE: 'evaluation',
    LINK: 'text_link',
    CALL: 1,
    ORDER: 'order'
};

export const EMOJI_TYPE = {
    BASIC: 'basic',
    BIG: 'big',
    CUSTOM: 'CUSTOM'
};
