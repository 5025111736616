<script setup lang="ts">
import { SyncOutlined, CloseCircleFilled } from '@ant-design/icons-vue';
import { LoadingStatus } from './constant';

const props = defineProps<{
    status: LoadingStatus;
}>();

const message = computed(() => {
    switch (props.status) {
        case LoadingStatus.Failed:
            return '网络异常';
        case LoadingStatus.Loading:
            return '加载中...';
        case LoadingStatus.Success:
            return '';
        default:
            return '未知';
    }
});

const type = computed(() => {
    switch (props.status) {
        case LoadingStatus.Failed:
            return 'error';
        case LoadingStatus.Loading:
            return 'info';
        case LoadingStatus.Success:
            return undefined;
        default:
            return 'warning';
    }
});
</script>

<template>
    <a-alert
        v-if="status !== LoadingStatus.Success"
        :type="type"
        :message="message"
        banner
    >
        <template #icon>
            <SyncOutlined
                v-if="status === LoadingStatus.Loading"
                spin
                class="color-#1890ff"
            />
            <CloseCircleFilled
                v-else-if="status === LoadingStatus.Failed"
                class="color-#ff4d4f"
            />
        </template>
    </a-alert>
</template>

<style scoped lang="scss"></style>
