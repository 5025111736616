<script setup lang="ts">
import {
    type IConversationModel,
    type IMessageModel,
    StoreName,
    TUIStore
} from '@tencentcloud/chat-uikit-engine';
import ChatHeader from './chat-header/index.vue';
import MessageList from './message-list/index.vue';
import MessageInput from './message-input/index.vue';
import MessageInputToolbar from './message-input-toolbar/index.vue';
import DefaultContent from './default-content.vue';
import TUIChatConfig from './config';

const currentConversationID = ref<string>();
const currentConversation = ref<IConversationModel>();
const messageInputRef = ref();
const messageListRef = ref();

TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
        currentConversationID.value = conversation?.conversationID;
        currentConversation.value = conversation;

        // TUIChat 每次切换会话，需要初始化 chatType;
        TUIChatConfig.setChatType(conversation?.type);
    }
});

const insertEmoji = (emojiObj: object) => {
    messageInputRef?.value?.insertEmoji(emojiObj);
};

const handleEditor = (message: IMessageModel, type: string) => {
    if (!message || !type) return;
    switch (type) {
        case 'reference':
            // todo
            break;
        case 'reply':
            // todo
            break;
        case 'reedit':
            if (message?.payload?.text) {
                messageInputRef?.value?.reEdit(message?.payload?.text);
            }
            break;
        default:
            break;
    }
};

watch(
    () => currentConversationID.value,
    newVal => {
        if (newVal) {
            messageInputRef?.value?.reEdit('');
            messageListRef?.value?.scrollToBottom();
        }
    }
);
</script>

<template>
    <DefaultContent v-if="!currentConversationID" />
    <div
        v-else
        class="flex flex-col h-full"
    >
        <ChatHeader :conversation="currentConversation" />
        <MessageList
            ref="messageListRef"
            class="flex-1 overflow-hidden"
            @handle-editor="handleEditor"
        />
        <MessageInputToolbar @insert-emoji="insertEmoji" />
        <MessageInput
            ref="messageInputRef"
            class="h-40"
        />
    </div>
</template>
