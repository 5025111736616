import { isNavigationFailure } from 'vue-router';
import NProgress from 'nprogress';
import type { Router } from 'vue-router';
import { LOGIN_NAME } from './constant';
import type { WhiteNameList } from './constant';
import { useUserStore } from '@/store/modules/user';
import { storageIsLogin } from '@/composables/storage';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const defaultRoutePath = '/session';

export function createRouterGuards(router: Router, whiteNameList: WhiteNameList) {
    router.beforeEach(async (to, _, next) => {
        NProgress.start();

        const userStore = useUserStore();

        if (storageIsLogin.value) {
            if (to.name === LOGIN_NAME) {
                next({ path: defaultRoutePath });
            } else {
                const hasRoute = router.hasRoute(to.name!);

                if (userStore.menus.length === 0) {
                    // 从后台获取菜单权限信息
                    try {
                        await userStore.afterLogin();
                    } catch (err) {
                        userStore.resetToken();
                        return next({ name: LOGIN_NAME });
                    }

                    if (!hasRoute) {
                        // 如果该路由不存在，可能是动态注册的路由，它还没准备好，需要再重定向一次到该路由
                        next({ ...to, replace: true });
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            }
        } else {
            // not login
            if (whiteNameList.some(n => n === to.name)) {
                // 在免登录名单，直接进入
                next();
            } else {
                next({ name: LOGIN_NAME, query: { redirect: to.fullPath }, replace: true });
            }
        }
    });
    router.afterEach((to, from, failure) => {
        if (isNavigationFailure(failure)) {
            console.error('failed navigation', failure);
        }

        NProgress.done(); // finish progress bar
    });

    router.onError(error => {
        console.log(error, '路由错误');
    });
}
