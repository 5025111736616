const TUIChat = {
    '【安全提示】本 APP 仅用于体验腾讯云即时通信 IM 产品功能，不可用于业务洽谈与拓展。请勿轻信汇款、中奖等涉及钱款的信息，勿轻易拨打陌生电话，谨防上当受骗。':
        "【Security Tips】This APP is only used to experience the functions of Tencent Cloud Instant Messaging products, and cannot be used for business negotiation and expansion. Don't trust money-related information such as remittances and lottery wins, don't make unfamiliar phone calls easily, and beware of being deceived.",
    '按Enter发送，Ctrl+Enter换行':
        'Press Enter to send a message and Ctrl+Enter to start a new line',
    表情: '[face]',
    查看更多: 'View more',
    撤回: 'Recall',
    撤回了一条消息: 'recalled a message',
    打开: 'Open',
    单击下载: 'Click download',
    点此投诉: 'Complain',
    点击处理: 'Click action',
    对方正在输入: 'Typing...',
    发起群视频: 'Make group video call',
    发起群语音: 'Make group voice call',
    发起通话: 'Start Call',
    发送失败: 'send failed',
    复制: 'Copy',
    该消息不存在: 'The message does not exist',
    关闭阅读状态: 'Read status closed',
    管理员开启全员禁言: 'The admin enables Mute All',
    欢迎使用TUICallKit: 'Welcome to TUICallKit',
    回到最新位置: 'Back to the latest location',
    回复: 'Reply',
    回复详情: 'Replies',
    集成TUICallKit: ' Integrate TUICallKit ',
    '检测到您暂未集成TUICallKit，无法体验音视频通话功能':
        'It is detected that you have not integrated TUI CallKit yet, so you cannot experience the audio and video call. ',
    结束群聊: 'Edit Group Call',
    进行体验: 'to experience.',
    经度: 'Longitude',
    拒绝: 'Reject',
    拒绝通话: 'Decline Call',
    开通腾讯实时音视频服务: 'Integration Tencent Real-Time Communication',
    聊天记录: '[chat record]',
    描述: 'Description',
    内容包含敏感词汇: 'Content contains sensitive words',
    您: 'You',
    您当前购买使用的套餐包暂未开通此功能:
        'The package you purchased does not support this ability.',
    您当前购买使用的套餐包暂未开通群消息已读回执功能:
        'The package you purchased does not support the read receipt of group messages.',
    您当前购买使用的套餐包暂未开通在线用户列表功能:
        'The package you purchased does not support the online user list.',
    您已被管理员禁言: 'You have been muted by the admin',
    您有: 'You have',
    请点击: 'Please click',
    请输入消息: 'Enter a message',
    取消: 'Cancel',
    取消通话: 'Cancel Call',
    全部已读: 'All read',
    确定: 'Submit',
    '确认重发该消息？': 'Confirm to resend the message?',
    人关闭阅读状态: ' read status closed',
    人未读: ' unread',
    人已读: ' read',
    删除: 'Delete',
    涉及敏感内容: 'Sensitive content',
    申请加入: 'Application for join in',
    视频: '[video]',
    视频通话: 'Video Call',
    条回复: 'replies',
    条入群申请: 'Item group application',
    条新消息: ' new messages',
    通话时长: 'Duration',
    同意: 'Agree',
    图片: '[photo]',
    '图片消息失败,无效的图片格式': 'Image message failed, invalid image format.',
    纬度: 'Latitude',
    未读: 'Unread',
    位置: '[location]',
    文件: '[file]',
    '文件不存在,请检查文件路径是否正确':
        'The file does not exist, please check if the file path is correct.',
    '文件大小超出了限制,如果上传文件,最大限制是100MB':
        'The file size exceeds the limit; if uploading a file, the maximum limit is 100MB.',
    '文件消息失败,禁止发送违规封禁的文件':
        'File message failed, sending prohibited and banned files is not allowed.',
    我: 'I',
    无法查看: 'unable to view',
    无法定位到原消息: 'Unable to navigate to the original message',
    无法收听: 'unable to listen',
    正在加载: 'Loading...',
    加载结束: 'Load end',
    无应答: 'No Answer',
    系统消息: 'System message',
    消息: '[message]',
    '消息或者资料中文本存在敏感内容,发送失败':
        'Sensitive content found in the message or information text, sending failed.',
    消息详情: 'Message Detail',
    '消息长度超出限制,消息长度不要超过12K':
        'Message length exceeds the limit; please keep the message length under 12K.',
    '消息中图片存在敏感内容,发送失败':
        ' Sensitive content is found in the picture in the message, sending failed.',
    选择提醒的人: 'Select Contact',
    已读: 'Read',
    已过撤回时限: 'Withdrawal deadline has passed',
    已接听: 'Answered',
    已拒绝: 'Rejected',
    已同意: 'Approved',
    引用: 'Reference',
    引用失败: 'quote failed',
    语音: '[audio]',
    语音通话: 'Voice Call',
    重新编辑: 'Re-edit',
    重新发送: 'Resend',
    转发: 'Forward',
    转发给: 'Forward to',
    自定义: '[custom message]',
    自定义消息: 'Custom message',
    '腾讯云 IM': 'Tencent Cloud Chat',
    空: 'Empty',
    文本包含本地审核拦截词: 'Text contains local moderation blocking words',
    按住说话: 'Hold to speak',
    抬起发送: 'Release to send',
    抬起取消: 'Release to cancel',
    正在录音: 'Recording',
    继续上滑可取消: 'Continue to swipe to cancel',
    '松开手指 取消发送': 'Release to cancel',
    此机型暂不支持复制功能: 'This model does not support the copy'
};

export default TUIChat;
