const TUIContact = {
    群聊通知: 'Group Chat Notifications',
    系统通知: 'System Notifications',
    我的群聊: 'My Group Chats',
    输入ID: 'Enter a groupID/userID',
    群名称: 'Group name',
    群ID: 'Group ID',
    加入群聊: 'Join group chat',
    解散群聊: 'Disband group chat',
    退出群聊: 'Quit group chat',
    进入群聊: 'Enter group chat',
    群类型: 'Group type',
    请填写验证信息: 'Enter the verification info',
    申请加入: 'Request to join',
    已申请: 'Requested',
    接受: 'Accept',
    拒绝: 'Refuse',
    已处理: 'Handled',
    添加群聊: 'add group chat',
    已加入: 'joined',
    该群组不存在: 'The group does not exist',
    加群: 'join',
    我的好友: 'Friend',
    发送消息: 'Send Message',
    个性签名: 'Signature',
    等待管理员同意:
        'Successful application, waiting for the administrator to agree to join the group application',
    加群成功: 'Join the group successfully',
    您已是群成员: 'You are already a group member',
    新的联系人: 'New Contacts',
    黑名单: 'Blocked List',
    无搜索结果: 'No Result',
    客服号: 'Customer Service List',
    ID: 'ID',
    联系人: 'User',
    群聊: 'Group',
    '添加好友/群聊': 'Add friend/group',
    备注名: 'remark',
    加入黑名单: 'block',
    删除好友: 'Delete friend',
    等待验证: 'waiting for verification',
    验证信息: 'verification info',
    发送申请: 'Send application',
    加入直播群: 'Join AVChatRoom',
    同意: 'Agree',
    删除好友成功: 'Delete friend success',
    删除好友失败: 'Delete friend failed',
    申请已发送: 'Application has been sent',
    申请发送失败: 'Application failed to send',
    进入会话失败: 'Failed to enter session',
    添加好友成功: 'Add friend success',
    同意好友申请失败: 'Failed to agree to friend request',
    拒绝成功: 'Reject friend request success',
    拒绝好友申请失败: 'Failed to reject friend request',
    解散群聊成功: 'Dismissed group success',
    解散群聊失败: 'Dismissed group failed',
    退出群组成功: 'Quit group success',
    退出群组失败: 'Quit group failed',
    申请入群失败: 'Application to join the group failed',
    加入黑名单失败: 'Add to blocklist failed',
    移除黑名单失败: 'Remove from blocklist failed',
    '修改备注失败: 备注长度不得超过 96 字节':
        'Failed to modify the remark: the length of the remark cannot exceed 96 bytes',
    修改备注成功: 'Remark modified success',
    修改备注失败: 'Remark modified failed'
};

export default TUIContact;
