import axios from 'axios';
import { message as Message } from 'ant-design-vue';
import type { AxiosRequestConfig, AxiosInstance } from 'axios';
import { storageAppVersion, storageIsLogin, storageToken } from '@/composables/storage';
import { ResultEnum } from '@/enums/request';
import router from '@/router';

const UNKNOWN_ERROR = '未知错误，请重试';
const service: AxiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    timeout: 60 * 1000
});

service.interceptors.request.use(
    async config => {
        config.headers['Authorization'] = storageToken.value ? `Bearer ${storageToken.value}` : '';
        config.headers['app-ver'] = storageAppVersion.value;
        config.headers['x-terminal'] = 'H5';

        return config;
    },
    error => {
        Message.error(error.message || UNKNOWN_ERROR);
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    async response => {
        // const res = response.data;
        const { config } = response;
        const { code, data } = response.data;
        let { msg } = response.data;

        const hasSuccess = Reflect.has(response.data, 'code') && code === ResultEnum.SUCCESS;

        if (hasSuccess) {
            config.successMessage && Message.success(config.successMessage);
            return data;
        }

        switch (code) {
            case ResultEnum.NOT_LOGIN:
                console.log('未登录或token错误');
                msg = '登录状态已失效';
                storageIsLogin.value = false;
                router.push('/login');
                break;
            case ResultEnum.LOGIN_MULTI:
                msg = '账号在其他设备登录';
                storageIsLogin.value = false;
                router.push('/login');
                break;
            // case ResultEnum.NO_PROJECT_AUTH:
            // case ResultEnum.NO_AUTH:
            //     console.log('无权限', msg);
            //     msg = '无权限';
            //     break;
            default:
                break;
        }

        const error = msg || UNKNOWN_ERROR;
        if (!config.hideErrorMessage) {
            Message.error(error);
        }
        return Promise.reject(error);
    },
    error => {
        if (error.message !== 'canceled') {
            Message.error(error.message || UNKNOWN_ERROR);
        }

        return Promise.reject(error);
    }
);

export const request = {
    get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return service.get(url, config);
    },

    post<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
        return service.post(url, data, config);
    },

    put<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
        return service.put(url, data, config);
    },

    delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return service.delete(url, config);
    }
};

Reflect.set(window, 'useRequest', () => request);
