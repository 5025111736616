export function filterMoney(money: number, rate = 0, precision = 2) {
    if (!money) {
        return 0;
    }
    if (!rate) {
        return money;
    }

    return +(money / rate).toFixed(precision);
}
