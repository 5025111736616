import type { IConversationModel } from '@tencentcloud/chat-uikit-engine';
import { request } from '@/utils/request';

/**
 * 获取可转接的客服列表
 */
export const getSwitchCustomerServiceList = () => {
    return request.post<{ list: SESSION_API.CustomerServiceInfo[] }>(
        '/student-app/customer/system/getSwitchKfList'
    );
};

/**
 * 转接客服
 */
export const exchangeCustomerService = (data: {
    groupId: string;
    customerImId: string;
    customerId: string;
}) => {
    return request.post('/student-app/session/exchange', data);
};

export const querySessionRecord = (params: SESSION_API.SessionParams) => {
    return request.post<any>('/student-app/session/query', params);
};

export const getCustomerList = (params: SESSION_API.CustomerParams) => {
    return request.post<any>('/student-app/customer/system/getCustomerList', params);
};

export const getEndSession = async () => {
    const data = await request.post<SESSION_API.ConversationItem[]>(
        '/student-app/session/getEndSession'
    );

    return data.map(conversation => {
        const res = {
            getAvatar: () => conversation.headImg,
            getShowName: () => conversation.nickName,
            isMuted: false,
            lastMessage: {
                getLastMessage: (field: string) =>
                    field === 'time' ? conversation.sessionTime : undefined,
                messageForShow: ''
            },
            unreadCount: 0,
            isPinned: false,
            groupProfile: {
                groupID: conversation.groupId
            }
        };

        return res as any as IConversationModel;
    });
};

export const reopenSession = (params: { groupId: string }) => {
    return request.post<void>('/student-app/session/reOpen', params);
};

export const getUserInfo = (params: { userId: string }) => {
    return request.post<SESSION_API.UserInfo>('/student-app/customer/system/getUserInfo', params);
};

export const getOrderList = (params: { userId: string }) => {
    return request.post<SESSION_API.OrderItem[]>(
        '/student-app/customer/system/getOrderList',
        params
    );
};

export const recordOpenSession = (params: { sessionId: string }) => {
    return request.post<string>('/student-app/session/record/openSession', params);
};

export const userOpenSession = (params: { groupId: string; userImId: string }) => {
    return request.post<string>('/student-app/session/user/openSession', params);
};

/**
 * 记录最后回复时间
 */
export const recordLastReplyTime = (groupId: string) => {
    return request.post('/student-app/session/recordReplyTime', {
        groupId,
        lastReplyTime: new Date().getTime(),
        type: 1
    });
};

export const getGroupUserInfo = (params: { groupId: string }) => {
    return request.post<SESSION_API.UserInfo>('/student-app/session/getUserInfo', params);
};
