<script setup lang="ts">
import EmojiPicker from './emoji-picker/index.vue';
import ImageUpload from './image-upload/index.vue';
import VideoUpload from './video-upload/index.vue';

const emits = defineEmits(['insertEmoji']);

const insertEmoji = (emojiObj: object) => {
    emits('insertEmoji', emojiObj);
};
</script>
<template>
    <div class="message-input-toolbar">
        <div class="message-input-toolbar-list">
            <EmojiPicker @insert-emoji="insertEmoji" />
            <ImageUpload image-source-type="album" />
            <VideoUpload video-source-type="album" />
        </div>
    </div>
</template>
<style lang="scss" scoped>
.message-input-toolbar {
    border-top: 1px solid #f4f5f9;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-list {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
</style>
