<template>
    <div class="h-full flex-center">
        <a-empty
            image="https://nss1.zsdx.cn/im/customer/empty.png"
            :image-style="{ height: '135px' }"
        >
            <template #description>
                <span class="text-gray">未选中联系人</span>
            </template>
        </a-empty>
    </div>
</template>
