<script setup lang="ts">
import type { IConversationModel } from '@tencentcloud/chat-uikit-engine';
import { TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import { sendMessages, sendTyping } from '../utils/sendMessage';
import MessageInputEditor from './message-input-editor.vue';
import MessageInputQuote from './message-input-quote/index.vue';

const props = defineProps({
    placeholder: {
        type: String,
        default: '输入内容后回车键(Enter键)发送'
    },
    muteText: {
        type: String,
        default: ''
    }
});

const emit = defineEmits(['sendMessage', 'resetReplyOrReference', 'onTyping']);
const editor = ref<InstanceType<typeof MessageInputEditor>>();
const currentConversation = ref<IConversationModel>();

TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
        currentConversation.value = conversation;
    }
});

const onTyping = (inputContentEmpty: boolean, inputBlur: boolean) => {
    sendTyping(inputContentEmpty, inputBlur);
};

const sendMessage = async () => {
    const editorContentList = editor.value?.getEditorContent();
    if (!editorContentList || !currentConversation.value) return;

    await sendMessages(editorContentList, currentConversation.value);
    emit('sendMessage');
    editor.value?.resetEditor();
};

const insertEmoji = (emoji: any) => {
    editor.value?.addEmoji(emoji);
};

const reEdit = (content: any) => {
    editor.value?.resetEditor();
    // resetReplyOrReference();
    editor.value?.setEditorContent(content);
};

defineExpose({
    insertEmoji,
    reEdit
});
</script>

<template>
    <div class="message-input-wrapper">
        <div :class="['message-input-container']">
            <MessageInputEditor
                ref="editor"
                :placeholder="props.placeholder"
                :is-muted="false"
                @send-message="sendMessage"
                @on-typing="onTyping"
            />
        </div>

        <!-- 引用消息 -->
        <MessageInputQuote />
    </div>
</template>

<style scoped lang="scss">
.message-input-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.message-input-container {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    border: none;
    overflow: hidden;
}
</style>
