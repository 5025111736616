export enum LoginStatus {
    // 成功
    SUCCESS = 1,
    // 需要绑定
    UNDER_BIND = 2,
    // 需要设置密码
    UNDER_SET_PWD = 3
}
export enum LoginType {
    PHONE = 'phone',
    PASSWORD = 'password'
}
