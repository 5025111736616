import type { DirectiveBinding, ObjectDirective } from 'vue';
import { usePermission } from '@/utils/permission';

function checkPermission(el: HTMLElement, binding: DirectiveBinding<string>) {
    const { hasPermission } = usePermission();
    const permissionsAllow = hasPermission(binding.value);

    if (!permissionsAllow) {
        el.remove();
    }
    return permissionsAllow;
}

export const permission: ObjectDirective = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        checkPermission(el, binding);
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
        checkPermission(el, binding);
    }
};
