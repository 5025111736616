import { defineStore } from 'pinia';
import type { RouteRecordRaw } from 'vue-router';
import { Modal } from 'ant-design-vue';
import { TUILogin } from '@tencentcloud/tui-core';
import { store } from '@/store';
import { getInfo, getUserRoleEnum, isCustomerService, loginByPhone, logout } from '@/api/user';
import { generatorDynamicRouter } from '@/router/generator-router';
import { resetRouter } from '@/router';
import { storageIsLogin, storageToken } from '@/composables/storage';
import { LoginStatus } from '@/api/user/enums';

export const useUserStore = defineStore('user', () => {
    const menus = ref<RouteRecordRaw[]>([]);
    const permissions = ref<string[]>([]);
    const isLogin = ref(false);
    const roleMap = ref<{ [key: number]: string }>({});
    const userInfo = ref<Partial<USER_API.UserInfo>>({});

    async function updateLoginStatus(status: number) {
        isLogin.value = status === 1;
        storageIsLogin.value = isLogin.value;
    }

    async function getUserRoleMap() {
        roleMap.value = await getUserRoleEnum();
    }

    async function updateUserInfo() {
        userInfo.value = await getInfo();
        // const res = await TUIUserService.getUserProfile({
        //     userIDList: [myUserId]
        // });
        // userInfo.value = res.data[0];
    }

    function resetToken() {
        // storageToken.value = '';
        menus.value = [];
        userInfo.value = {};
    }

    async function handleLogin(params: USER_API.LoginByPhoneReq) {
        try {
            const res = await loginByPhone(params);

            if (res.state === LoginStatus.SUCCESS) {
                storageToken.value = res.authToken;
            } else {
                return Promise.reject('登录失败');
            }

            const isCustomer = await isCustomerService();
            if (!isCustomer) {
                Modal.warning({
                    title: () => '提示',
                    content: () => '您不是客服人员，无法登录客服系统'
                });

                await logout();
                return Promise.reject('您不是客服人员，无法登录客服系统');
            }

            await updateLoginStatus(1);

            return afterLogin();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function afterLogin() {
        try {
            // userInfo.value = await getInfo();
            // 生成路由
            const generatorResult = await generatorDynamicRouter();
            menus.value = generatorResult.menus.filter(item => !item.meta?.hideInMenu);

            return { menus: menus.value, userInfo: userInfo.value };
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function handleLogout() {
        await logout();
        await updateLoginStatus(0);

        try {
            await TUILogin.logout();
        } catch (e) {
            console.error(e);
        }

        resetToken();
        resetRouter();
    }

    return {
        menus,
        userInfo,
        permissions,
        login: handleLogin,
        logout: handleLogout,
        afterLogin,
        resetToken,
        updateLoginStatus,
        getUserRoleMap,
        updateUserInfo
    };
});

// 在组件setup函数外使用
export function useUserStoreWithOut() {
    return useUserStore(store);
}
