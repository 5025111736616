import type { App } from 'vue';
import { permission } from '@/directives/permission';

const directives = {
    permission
};

/**
 * 注册全局自定义指令
 */
export function setupDirectives(app: App) {
    Object.keys(directives).forEach(key => {
        app.directive(key, directives[key]);
    });
}
