import type { RouteRecordRaw } from 'vue-router';
import { notFound, errorRoute } from './modules/error';
import { REDIRECT_ROUTE } from './modules/besidesLayout';
import outsideLayout from './outsideLayout';
import { usePermission } from '@/utils/permission';
import commonRoutes from '@/router/modules';
import router, { routes } from '@/router';

// 需要放在所有路由之后的路由
const endRoutes: RouteRecordRaw[] = [REDIRECT_ROUTE, errorRoute, notFound];
const { accessRouter } = usePermission();

/**
 * 将路径中重复的正斜杆替换成单个斜杆隔开的字符串
 * @param path 要处理的路径
 * @returns {string} 将/去重后的结果
 */
export const uniqueSlash = (path: string) => path.replace(/(https?:\/)|(\/)+/g, '$1$2');

/**
 * 动态生成菜单
 */
export const generatorDynamicRouter = () => {
    try {
        const layout = routes.find(item => item.name == 'Layout')!;

        // 给公共路由添加namePath
        generatorNamePath(commonRoutes);
        const filterCommonRoutes = filterNoAuthRoutes(commonRoutes);
        const menus = [...filterCommonRoutes, ...endRoutes];

        layout.children = menus;
        const removeRoute = router.addRoute(layout);
        // 获取所有没有包含children的路由，上面addRoute的时候，vue-router已经帮我们拍平了所有路由
        const filterRoutes = router
            .getRoutes()
            .filter(
                item =>
                    (!item.children.length || Object.is(item.meta?.hideChildrenInMenu, true)) &&
                    !outsideLayout.some(n => n.name === item.name)
            );
        // 清空所有路由
        removeRoute();
        layout.children = [...filterRoutes];
        // 重新添加拍平后的路由
        router.addRoute(layout);
        console.log('生成路由完成', router.getRoutes());

        return Promise.resolve({
            menus,
            routes: layout.children
        });
    } catch (error) {
        console.error('生成路由时出错', error);
        return Promise.reject(`生成路由时出错: ${error}`);
    }
};

/**
 * 主要方便于控制a-menu的open-keys，即控制左侧菜单应当展开哪些菜单
 */
export const generatorNamePath = (
    routes: RouteRecordRaw[],
    namePath?: string[],
    parent?: RouteRecordRaw
) => {
    routes.forEach(item => {
        if (item.meta && typeof item.name === 'string') {
            item.meta.namePath = Array.isArray(namePath) ? namePath.concat(item.name) : [item.name];
            item.meta.fullPath = parent?.meta?.fullPath
                ? [parent.meta.fullPath, item.path].join('/')
                : item.path;
            item.meta.fullPath = uniqueSlash(item.meta.fullPath);

            if (item.children?.length) {
                generatorNamePath(item.children, item.meta.namePath, item);
            }
        }
    });
};

/**
 * 过滤掉没有权限的路由
 */
export function filterNoAuthRoutes(routes: RouteRecordRaw[]) {
    const collector: (RouteRecordRaw | null)[] = routes.map(element => {
        if (!accessRouter(element)) return null;

        if (!element.children || element.children.length == 0) {
            if (accessRouter(element)) {
                return element;
            } else {
                return null;
            }
        }
        const subMenuList = filterNoAuthRoutes(element.children);

        if (subMenuList.length) {
            element.children = subMenuList;
            return element;
        }
        return null;
    });

    return collector.filter(Boolean) as RouteRecordRaw[];
}
