import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import { setupRouter } from '@/router';
import { setupStore } from '@/store';
import { initImSdk, setupAssets, setupDirectives } from '@/plugins';
import { TUIChatKit, TUIComponents } from '@/TUIKit';
import '@/utils/request';

import './utils/db';

const app = createApp(App);

TUIChatKit.components(TUIComponents, app);
TUIChatKit.init();

Sentry.init({
    app,
    dsn: 'https://3554e79f0134bf47ff0b345cc9d090e4@sentry.zsdx.cn/34',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function setupPlugins() {
    // 引入静态资源
    setupAssets();
    // 注册全局自定义指令，如：v-permission权限指令
    setupDirectives(app);
}

async function setupApp() {
    // 挂载vuex状态管理
    setupStore(app);

    // 挂载路由
    await setupRouter(app);

    app.mount('#app');

    // 初始化IM SDK
    await initImSdk();
}

setupPlugins();

setupApp();
