<script lang="ts" setup>
import type { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import MessageGoods from './message-goods/index.vue';
import MessageOrder from './message-order/index.vue';
import MessageAutoReply from './message-auto-reply/index.vue';
import { JSONToObject } from '@/TUIKit/utils';
import { CHAT_MSG_CUSTOM_TYPE } from '@/TUIKit/constant';
import type { ICustomMessagePayload } from '@/TUIKit/interface';
import { CustomMessageType } from '@/enums/message';

interface Props {
    messageItem: IMessageModel;
    content: any;
}

const props = withDefaults(defineProps<Props>(), {
    messageItem: undefined,
    content: undefined
});

const custom = ref();
const message = ref<IMessageModel>();
const extension = ref();
const isCustom = ref<ICustomMessagePayload>({
    businessID: ''
});

const customData = computed(() => {
    const { payload } = props.messageItem;
    return JSON.parse(payload.data);
});

console.log('customData', customData.value);

watchEffect(() => {
    custom.value = props.content;
    message.value = props.messageItem;
    const { payload } = props.messageItem;
    isCustom.value = payload.data || '';
    isCustom.value = JSONToObject(payload.data);
    if (payload.data === CHAT_MSG_CUSTOM_TYPE.SERVICE) {
        extension.value = JSONToObject(payload.extension);
    }
});
</script>

<template>
    <div class="custom">
        <MessageGoods
            v-if="customData.type === CustomMessageType.GOODS"
            :goods-cover="customData.icon"
            :goods-id="customData.ext?.goodsId"
            :goods-price="customData.ext?.goodsPrice"
            :goods-title="customData.title"
        />
        <MessageAutoReply
            v-else-if="customData.type === CustomMessageType.AUTO_REPLY"
            :welcome-config="customData.ext?.welcomeConfig"
            :faq-config-list="customData.ext?.faqConfigList"
        />
        <MessageOrder
            v-else-if="customData.type === CustomMessageType.ORDER"
            :cover="customData.icon"
            :order-id="customData.ext?.orderNo"
            :price="customData.ext?.price"
            :title="customData.title"
            :num="customData.ext?.num"
        />

        <div v-else>不支持的消息类型，请在手机上进行查看</div>
    </div>
</template>
<style lang="scss" scoped>
a {
    color: #679ce1;
}
.custom {
    font-size: 14px;
    h1 {
        font-size: 14px;
        color: #000000;
    }
    h1,
    a,
    p {
        font-size: 14px;
    }
    .evaluate {
        ul {
            display: flex;
            padding-top: 10px;
        }
        &-list {
            display: flex;
            flex-direction: row;
            &-item {
                padding: 0px 2px;
            }
        }
    }
    .order {
        display: flex;
        main {
            padding-left: 5px;
            p {
                width: 145px;
                line-height: 17px;
                font-size: 14px;
                color: #999999;
                letter-spacing: 0;
                margin-bottom: 6px;
                word-break: break-word;
            }
            span {
                line-height: 25px;
                color: #ff7201;
            }
        }
        img {
            width: 67px;
            height: 67px;
        }
    }
}
</style>
